import { propertyOf } from "../../../RAFComponents/helpers/utils";
import { AttributeRow } from "../../../RAFComponents/models/Common/AttributeRow";
import { AttributeSettingsRow } from "../../../RAFComponents/models/Common/AttributeSettingsRow";
import { RAFRuleAction } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { ImageSettings, RatingSettings, ValueJson, VideoSettings } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { RAFEntityBase } from "../../../RAFComponents/models/Common/RAFEntityBase";
import { RAFDetailsSection, RAFPageRelatedListRow } from "../RAFPageRelatedList/RAFPageRelatedListRow";

export class ColumnField {
    AttributeUID?: string;
    DefaultValue?: string;
    IsHidden?: boolean;
    IsEditable?: boolean;
    IsRequired?: boolean;
    DataType?: string;
    FieldName?: string;
    DisplayName?: string;
    UIType?: string;
    ValueJson?: ValueJson[];
    RelatedEntity?: string;
    Placeholder?: string;
    ShowLabel?: boolean;
    NavigationEnabled?: boolean;
    UISettings?: RatingSettings | ImageSettings | VideoSettings;
}

export class RelatedSectionJM {
    UID?: string;
    AttributeUID?: string;
    AttributeName?: string;
    RelationType?: string;
    RelatedEntityUID?: string;
    RelatedEntity?: string;
    ViewUID?: string;
    ViewName?: string;
    RowLimit?: number;
    NavigationEnabled?: boolean;
}

export class RelatedForm {

    RelatedList?: RAFPageRelatedListRow[];
}

export class RelatedListJM {
    Title?: string;
    DisplayOrder?: number;
    VisualType?: string;
    Position?: string;
    IsVisible?: boolean;
}

export class Column {
    UID?: string;
    Fields?: ColumnField[];
    Fields1?: AttributeRow[];
}

export class SectionRow {
    UID?: string;
    Name?: string;
    IsCollapsed?: boolean;
    ColumnCount?: number;
    DisplayOrder?: number;
    ShowHeader?: boolean;
    Columns?: Column[];
    SectionType?: string;
    Placeholder?: string;
    RelatedSections?: RelatedSectionJM[];
}

export class RAFComponentHeadingModel {
    UID?: string;
    Name?: string;
    DataType?: RAFDataType;
    Data?: string;
}

export class RAFComponentVideoModel {
    UID?: string;
    Name?: string;
    DisplayName?: string;
    DataType?: RAFDataType;
    URL?: string;
}

export class RAFComponentImageModel {
    UID?: string;
    Name?: string;
    DisplayName?: string;
    DataType?: RAFDataType;
    URL?: string;
}

export class RAFComponentRichTextModel {
    UID?: string;
    Name?: string;
    DisplayName?: string;
    DataType?: RAFDataType;
    DefaultValue?: string;
}

export class RAFComponentSignatureModel {
    UID?: string;
    Name?: string;
    DisplayName?: string;
    DataType?: RAFDataType;
}

export class RAFComponentFormModel {
    UID?: string;
    Name?: string;
    DisplayName?: string;
    EntityUID?: string;
    EntityName?: string;
    DataType?: RAFDataType | string;
    RAFFormAction?: 'Create' | 'Update';
    PageLayoutId?: string;
    Components?: RAFComponentInputModel[];
    IsUpdate?: boolean;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    RelatedAttribute?: string;
    RelatedAttributeUID?: string;
    FormType?: string;
    RelatedContext?: string;
}

export class RAFComponentInputModel {
    UID?: string;
    Name?: string;
    DisplayName?: string;
    EntityUID?: string;
    DataType?: string;
    ValueJson?: ValueJson[];
    Multiselect?: boolean;
    Visible?: boolean;
    Searchable?: boolean;
    FeedsEnabled?: boolean;
    InlineEdit?: boolean;
    ShowInView?: boolean;
    ShowInFilter?: boolean;
    TenantId?: number;
    DBDataType?: string;
    IsSystem?: boolean;
    IsDefault?: boolean;
    IsQueryable?: boolean;
    IsSuperAdmin?: boolean;
    AttributeSettings?: AttributeSettingsRow;
    DisplayOrder?: number;
    CustomProps1?: string;
    RelatedEntities?: string;
    CreatedDate?: string;
    ModifiedDate?: string;
    CreatedBy?: string;
    ModifiedBy?: string;
    AppId?: string;
    CurrentNumber?: number;
    DigitFormat?: string;
    FormulaDataType?: string;
    CastDataType?: string;
    FunctionName?: string;
    ViewName?: string;
    IsMasterDetail?: boolean;
    Expression?: string;
}

export class RAFComponentModel {
    //component?: { Name:string,Type: string, Settings: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentFormModel | RAFComponentInputModel;
    component?: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentFormModel | RAFComponentInputModel;
}

export class RAFFormDataModel {
    components?: RAFComponentModel[];
}

export class RAFQuestionModel {
    UID?: string;
    Name?: string;
    QuestionSettings?: RAFQuestionSettings;
    QuestionOptions?: RAFQuestionOptions[];
}

export class RAFQuestionSettings {
    Multiselect?: boolean;
    DisplayType?: string;
    StarCount?: number;
    ResponseOption?: string;
}

export class RAFQuestionOptions {
    Title?: string;
    Value?: string;
    Score?: number;
}

export class IPageLayoutJMDto {
    UID?: string;
    Name?: string;
    PageType?: string;
    EntityUID?: string;
    Layout?: { sections: SectionRow[]; };
    //Components?: { components: RAFDataModelRow[]; };
    FormStructure?:any
    Components?: RAFDataModelComponentRow;
    RelatedSectionLayout?: { sections: RAFDetailsSection[]; };
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class IPageLayoutJM {
    UID?: string;
    Name?: string;
    PageType?: string;
    EntityUID?: string;
    Layout?: { sections: SectionRow[]; };
    //Components?: { components: RAFDataModelRow[]; };
    FormStructure?:any;
    Components?: RAFDataModelComponentRow;
    RelatedSectionLayout?: { sections: RAFDetailsSection[]; };
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class RAFFormIOModel {
    Components?: RAFDataModelRow[];
}

export class RAFFormIOComponentModel {
    UID?: string;
    Name?: string;
    DisplayName?: string;

    EntityUID?: string;
    DataType?: string;
    ValueJson?: ValueJson[];
    Multiselect?: boolean;
    Visible?: boolean;
    Searchable?: boolean;
    FeedsEnabled?: boolean;
    InlineEdit?: boolean;
    ShowInView?: boolean;
    ShowInFilter?: boolean;
    IsSystem?: boolean;
    IsDefault?: boolean;
    IsMasterDetail?: boolean;
    AttributeSettings?: AttributeSettingsRow;
    Description?: string;
    DisplayOrder?: number;
    CreatedDate?: string;
    ModifiedDate?: string;
    CreatedBy?: string;
    ModifiedBy?: string;
    RelatedEntities?: string;
    AppId?: string;
    IsQueryable?: boolean;
    IsSuperAdmin?: boolean;
    CurrentNumber?: number;
    Expression?: string;
    FormulaDataType?: string;
    CastDataType?: string;
    FunctionName?: string;
    ViewName?: string;
    Columns?: string[];
    UISettings?: RatingSettings | ImageSettings | VideoSettings;
    Portal?: string;
    PortalUID?: string;
    CustomProps1?: string;
    DigitFormat?: string;
    TenantId?: number;
    DBDataType?: string;

    InputType?: string;
    ResponseOption?: string;
    Placeholder?: string;
    ShowLabel?: boolean;
    Components?: RAFDataModelRow[];
    ParentUID?: string;
    ShowInList?: boolean;
    ShowHeader?: boolean;
    NavigationEnabled?: boolean;
    Response?: string;

    ColumnCount?: number;
    IsCollapsed?: boolean;
    IsCollapsible?: boolean;
    FieldToSave?: boolean;

    FormUID?: string;
    FormType?: string;

    EnableDocument?: boolean;
    EnableTask?: boolean;
    EnableComment?: boolean;

    FormRules?: RAFRuleAction[];

    RelatedSection?: RAFRelatedModuleSection[];
    ColumnWidth?: [];

    HasStep?: boolean;
    StepID?: string;
}

export class RAFDataModelComponentRow {
    Components?: RAFDataModelRow[];
}

export class RAFDataModelRow { //RAFDataModelRow
    UID?: string;
    Name?: string;
    DisplayName?: string;
    Title?: string;

    EntityUID?: string;
    DataType?: string;
    ValueJson?: ValueJson[];
    Multiselect?: boolean;
    Visible?: boolean;
    Searchable?: boolean;
    FeedsEnabled?: boolean;
    InlineEdit?: boolean;
    ShowInView?: boolean;
    ShowInFilter?: boolean;
    IsSystem?: boolean;
    IsDefault?: boolean;
    IsMasterDetail?: boolean;
    AttributeSettings?: AttributeSettingsRow;
    Description?: string;
    DisplayOrder?: number;
    CreatedDate?: string;
    ModifiedDate?: string;
    CreatedBy?: string;
    ModifiedBy?: string;
    RelatedEntities?: string;
    AppId?: string;
    IsQueryable?: boolean;
    IsSuperAdmin?: boolean;
    CurrentNumber?: number;
    Expression?: string;
    FormulaDataType?: string;
    CastDataType?: string;
    FunctionName?: string;
    ViewName?: string;
    Columns?: string[];
    UISettings?: RatingSettings | ImageSettings | VideoSettings;
    Portal?: string;
    PortalUID?: string;
    CustomProps1?: string;
    DigitFormat?: string;
    TenantId?: number;
    DBDataType?: string;

    InputType?: string;
    ResponseOption?: string;
    Placeholder?: string;
    ShowLabel?: boolean;
    Components?: RAFDataModelRow[];
    ParentUID?: string;
    ShowInList?: boolean;
    ShowHeader?: boolean;
    NavigationEnabled?: boolean;
    Response?: string;

    ColumnCount?: number;
    IsCollapsed?: boolean;
    IsCollapsible?: boolean;
    FieldToSave?: boolean;

    FormUID?: string;
    FormType?: string;

    EnableDocument?: boolean;
    EnableTask?: boolean;
    EnableComment?: boolean;

    FormRules?: RAFRuleAction[];

    RelatedSection?: RAFRelatedModuleSection[];
    ColumnWidth?: [];

    HasStep?: boolean;
    StepId?: string;
    Width?: string;
    MinWidth?: string;
    MaxWidth?: string;
    TitleLocation?: string;
    DisplayInNewLine?: boolean;
}

export class RAFRelatedModuleSection {
    ModuleName?: string;
    DisplayName?: string;
    Mode?: string;
}

export class PageLayoutJM extends IPageLayoutJM implements RAFEntityBase {
    AttributesList?: AttributeRow[];
    getClassName?() { //use entityname
        return 'pagelayout';
    }
    getIdField?(): string {
        return propertyOf<PageLayoutJM>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "PageLayout/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "PageLayout/Save";
    }
}
