import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import * as React from "react";
import { PropsWithChildren } from "react";
import { FormRenderProps } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { tabAnimationSettings } from "../../../RAFComponents/helpers/AppHelper";
import {
  ConvertSystemName,
  ConvertToCamelCase,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFFormContext } from "../../../RAFComponents/Inputs/RFFUtils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import DocumentSummary from "../../../RAFModules/ActiveContacts/Document/DocumentSummary";
import MyTaskWidget from "../../../RAFModules/ActiveContacts/Task/MyTaskWidget";
import { RAFEntityContext } from "../../../RAFModules/Common/Providers/RAFEntityProvider";
import { RAFObjectContext } from "../../../RAFModules/Common/Providers/RAFObjectContextProvider";
import { RAFPageLayoutContext } from "../../../RAFModules/Common/Providers/RAFPageLayoutProvider";
import {
  getElement,
  getInitialValueForAttribute,
} from "../../../RAFModules/Common/RAFFieldHelper";
import RAFRelatedButtons from "../../../RAFModules/Common/RAFRelatedButtons";
import {
  RAFDataModelRow,
  RAFRelatedModuleSection,
} from "../RAFPage/PageLayoutJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";

interface IProps {
  formDataFieldName?: string;
  formDataModelFieldName?: string;
  validateFields?: boolean;
  isPreviewMode?: boolean;
  allowSubmit?: boolean;
  autoSave?: boolean;
  isDisabled?: boolean;
  rafComponent: RAFDataModelRow;
  showLabel?: boolean;
  moduleName?: string;
  viewAttributes?: QueryAttributeJM[];
}

function RAFFormInputModelRender({
  rafComponent,
  showLabel,
  moduleName,
  viewAttributes,
  ...props
}: PropsWithChildren<IProps>) {
  const rafPageLayoutContext = React.useContext(RAFPageLayoutContext);
  const mode = isNotNullAndUndefined(rafPageLayoutContext)
    ? rafPageLayoutContext.mode
    : null;
  const rafObject = React.useContext(RAFObjectContext);
  const entityContext = React.useContext(RAFEntityContext);
  const entity = entityContext.entity;
  const formLibrary = entityContext.formLibrary;
  const rafFormContextValue: FormRenderProps = React.useContext(RAFFormContext);
  let navigate = useNavigate();
  const renderformComponent = (component?: RAFDataModelRow) => {
    let retVal = <div></div>;
    const showComponet = component.Visible;
    const isDisabled =
      props.isDisabled === true
        ? true
        : component &&
          component.AttributeSettings &&
          component.AttributeSettings.IsReadonly === true
          ? true
          : false;

    const showComponentLabel = isNotNullAndUndefined(showLabel)
      ? showLabel
      : component.ShowLabel;

    let componentName;
    let selectedField: QueryAttributeJM;

    if (isNotEmptyArray(viewAttributes)) {
      let filteredQueryAttribute = viewAttributes.filter(
        (x) => x.DataType !== "tsvector"
      );
      // selectedField = viewAttributes.find(
      //   (x) => x.AttributeUID === component.UID && x.IsEditable === true
      // );
      selectedField = filteredQueryAttribute.find(
        (x) => x.AttributeUID === component.UID
      );
      componentName = selectedField && selectedField.PropertyName;
    }

    let formItemTitleLocation = rafComponent["TitleLocation"];
    let formValues = rafFormContextValue && rafFormContextValue.values;
    let fieldName = ConvertToCamelCase(component.Name);
    if (showComponet) {
      if (props.isPreviewMode) {
        //const defaultValue = getInitialValueForAttribute(null, component);
        retVal = getElement(
          null,
          component.UID,
          component.DataType,
          isNotNullAndUndefined(componentName) ? componentName : component.Name, //component.Name
          component.DisplayName,
          component.Title,
          props.validateFields,
          component.AttributeSettings.IsRequired,
          showComponentLabel,
          isDisabled,
          component.AttributeSettings.UIType,
          component.Multiselect,
          component.ValueJson,
          component.RelatedEntities,
          component.AttributeSettings.DefaultValue, // defaultValue,//component.AttributeSettings.DefaultValue,
          null,
          mode, // null, //"Edit" | "Details" richtextEditor
          component.Placeholder,
          component.DisplayName,
          isNotNullAndUndefined(props.formDataFieldName)
            ? props.formDataFieldName
            : null,
          component.Description,
          props.isPreviewMode,
          null,
          formValues,
          component.AttributeSettings.DescriptionAsLabel,
          formItemTitleLocation,
          formLibrary,
          fieldName
        );
      } else {
        if (component.DataType === RAFDataType.Paragraph) {
          //const defaultValue = getInitialValueForAttribute(null, component);
          retVal = getElement(
            null,
            component.UID,
            component.DataType,
            isNotNullAndUndefined(componentName)
              ? componentName
              : component.Name, //component.Name
            component.DisplayName,
            component.Title,
            props.validateFields,
            component.AttributeSettings.IsRequired,
            showComponentLabel,
            isDisabled,
            component.AttributeSettings.UIType,
            component.Multiselect,
            component.ValueJson,
            component.RelatedEntities,
            null, //  component.AttributeSettings.DefaultValue,
            null,
            mode, // null, //"Edit" | "Details" richtextEditor
            component.Placeholder,
            component.DisplayName,
            isNotNullAndUndefined(props.formDataFieldName)
              ? props.formDataFieldName
              : null,
            component.Description,
            props.isPreviewMode,
            null,
            formValues,
            component.AttributeSettings.DescriptionAsLabel,
            formItemTitleLocation,
            formLibrary
          );
        } else if (isNotNullAndUndefined(selectedField)) {
          //const defaultValue = getInitialValueForAttribute(selectedField);
          retVal = getElement(
            entity.EntityName,
            selectedField.AttributeUID,
            selectedField.DataType,
            isNotNullAndUndefined(componentName)
              ? componentName
              : component.Name, //component.Name
            selectedField.DisplayName,
            component.Title,
            props.validateFields,
            isNotNullAndUndefined(component.AttributeSettings.IsRequired)
              ? component.AttributeSettings.IsRequired
              : selectedField.IsRequired,
            showComponentLabel,
            isDisabled,
            selectedField.UIType,
            selectedField.IsMultiSelect,
            selectedField.ValueJson,
            selectedField.RelatedEntities,
            isNotNullAndUndefined(component.AttributeSettings.DefaultValue)
              ? component.AttributeSettings.DefaultValue
              : selectedField.DefaultValue, //defaultValue, //  selectedField.DefaultValue
            null,
            mode, // null, //"Edit" | "Details" richtextEditor
            component.Placeholder,
            component.DisplayName,
            isNotNullAndUndefined(props.formDataFieldName)
              ? props.formDataFieldName
              : null,
            component.Description,
            props.isPreviewMode,
            component.FormRules,
            formValues,
            component.AttributeSettings.DescriptionAsLabel,
            formItemTitleLocation,
            formLibrary,
            fieldName
          );
        }
      }
    }

    return retVal;
  };

  const redirectToUpdate = (uid: string) => {
    navigate(`/RAF/Update/${moduleName}/${uid}`);
  };

  let tabObj: TabComponent;

  const renderTabContent = (
    relatedSection?: RAFRelatedModuleSection,
    component?: RAFDataModelRow
  ) => {
    if (relatedSection.ModuleName === RAFEntityName.Activity) {
      return (
        <RAFRelatedButtons
          relatedEntity={relatedSection.ModuleName}
          secondaryRelatedToUID={component.UID}
          relatedSection={relatedSection}
        />
      );
    } else if (relatedSection.ModuleName === RAFEntityName.Task) {
      return (
        <RAFRelatedButtons
          relatedEntity={relatedSection.ModuleName}
          secondaryRelatedToUID={component.UID}
          relatedSection={relatedSection}
        />
      );
    } else if (relatedSection.ModuleName === RAFEntityName.Document) {
      return (
        <RAFRelatedButtons
          relatedEntity={relatedSection.ModuleName}
          secondaryRelatedToUID={component.UID}
          relatedSection={relatedSection}
        />
      );
    }
  };

  if (isNotNullAndUndefined(rafComponent)) {
    if (
      isNotNullAndUndefined(rafComponent.RelatedSection) &&
      rafComponent.RelatedSection.length > 0
    ) {
      let relatedSections = rafComponent.RelatedSection;

      return (
        <div>
          <div className="col-12">{renderformComponent(rafComponent)}</div>
          <TabComponent
            cssClass="e-fill custom-tab-footer-border bp-tab active-bg-gray formLibraryTab"
            //heightAdjustMode="Auto"
            animation={tabAnimationSettings}
            ref={(tab) => (tabObj = tab)}
            height="100%"
          >
            <TabItemsDirective>
              {relatedSections.map((section, index) => {
                return (
                  <TabItemDirective
                    key={"section_" + index}
                    header={{ text: section.DisplayName }}
                    content={renderTabContent.bind(this, section, rafComponent)}
                  />
                );
              })}
            </TabItemsDirective>
          </TabComponent>
        </div>
      );
    } else {
      return (
        <div>
          <div className="col-12">{renderformComponent(rafComponent)}</div>
          {(rafComponent.EnableDocument === true ||
            rafComponent.EnableComment === true ||
            rafComponent.EnableTask === true) && (
              <div className="manageStep-tab-col mt-1">
                <div className="w-100 manageStep-tab d-flex px-3 py-2">
                  <div>
                    {rafComponent.EnableDocument === true && (
                      <DocumentSummary
                        relatedUID={rafObject.objectId}
                        relatedTo={rafObject.objectName}
                        relatedToType={entity.EntityName}
                        moduleName={entity.DisplayName}
                        showExpandBtn={false}
                        // showAddBtn={true}
                        showUploadDocumnetBtnOnly
                        headerRowClassName="custom-section-header"
                        secondaryRelatedToUID={rafComponent.UID}
                        addBtnContent={"Add Document"}
                        saveContextObject={rafObject.saveObject}
                        contextObject={
                          rafFormContextValue && rafFormContextValue.values
                        }
                        onUploadCompleted={(id) => redirectToUpdate(id)}
                      />
                    )}
                  </div>
                  <div className="ms-2">
                    {rafComponent.EnableTask === true && (
                      <MyTaskWidget
                        //key={this.state.myTaskWidgetKey}
                        relatedToUID={rafObject.objectId}
                        relatedTo={rafObject.objectName}
                        relatedToType={entity.EntityName}
                        showAddBtn
                        // showAddBtn={true}
                        addBtnContent={"Add Task"}
                        secondaryRelatedToUID={rafComponent.UID}
                      // refreshOnCompleteTask={() => this.getContact()}
                      // relatedCommentsSectionUpdate={() =>
                      //     this.refreshOnCommentsUpdated()
                      // }
                      // refreshTaskAndComment={() =>
                      //     this.refreshTaskAndComment()
                      // }
                      // refreshOnCommentsUpdated={() =>
                      //     this.refreshOnCommentsUpdated()
                      // }
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      );
    }
  } else {
    return;
  }
}
export default RAFFormInputModelRender;
