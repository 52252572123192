import { ColumnsModel } from "@syncfusion/ej2-react-querybuilder";
import { initializeApp } from "firebase/app";
import { collection, getDocs, initializeFirestore, limit, query, where } from "firebase/firestore";
import * as R from "ramda";
import { msalInstance } from "../..";
import { GridColumnHeader, GridColumnTemplates, GridColumnWidth } from "../../RAFComponents/Grid/RAFSFGridUtils";
import { RAFCustomFilter, RAFCustomOperator } from "../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../RAFComponents/Utility/RAFToastComponent";
import { getAllForms, getFBSuffix, getSessionStorage, removeSessionStorage, setSessionStorage } from "../../RAFComponents/helpers/AppHelper";
import { ConvertSystemName, Guid, IsNullOrWhiteSpace, base64ToFile, getSFColumnTypeByRAFDatatype, getSaveRequest, isConnectedToInternet, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../RAFComponents/helpers/utils";
import { AttributeRow } from "../../RAFComponents/models/Common/AttributeRow";
import { FormLibraryRow, IFormLibraryDto, RAFFormType } from "../../RAFComponents/models/Common/FormLibraryRow";
import { DocumentPreSignedUrlRequest, EntityListRequest, ListServiceRequest, ModuleListRequest, PageRelatedListRequest, TenantUserRequest } from "../../RAFComponents/models/Common/ListRequest";
import { QueryAttributeJM, ValueJson } from "../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFDataType, RAFUIType } from "../../RAFComponents/models/Common/RAFDataType";
import { LookUpRow } from "../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { firebaseConfig } from "../../RAFFirebase";
import { ContentLibraryCurrentStatus, fileUpload, getResizedImage } from "../../RAFModules/ActiveContacts/Document/Library/DocumentHelper";
import { UserRow } from "../../RAFModules/ActiveContacts/User/UserRow";
import { ContentType, RAFBusinessAction, RAFFilePreSignedURL, StorageKey } from "../../constants/Common/Constants";
import { BusinessRuleListRequest, RAFEntityType, RMStorageKey } from "../../constants/Common/RMConstants";
import { BusinessProcessTemplateRow } from "../RMModules/BusinessProcess/Template/BusinessProcessTemplateRow";
import { BusinessRuleTriggerRow } from "../RMModules/BusinessRuleTrigger/BusinessRuleTriggerRow";
import { BusinessAction } from "../RMModules/BusinessRules/BusinessAction";
import { EntityRow, EntitySimpleJM } from "../RMModules/Entity/EntityRow";
import { ConvertFormLibraryDtoToFormLibrary } from "../RMModules/FormLibrary/FormLibraryMappingRegister";
import { ModuleRow } from "../RMModules/Modules/ModuleRow";
import { PageLayoutJM, RAFDataModelRow } from "../RMModules/RAFPage/PageLayoutJM";
import { ConvertPageLayoutJMToPageLayoutJMDto } from "../RMModules/RAFPage/PageLayoutMappingRegister";
import { RAFPageRelatedListRow } from "../RMModules/RAFPageRelatedList/RAFPageRelatedListRow";
import { TemplateRow } from "../RMModules/Templates/TemplateRow";
import { RAFComponentRow, RAFComponentType } from "./../../RAFFormBuilder/RAFComponentRow";
//import { AppThunkActionAsync } from "@Store/index";
import Resizer from 'react-image-file-resizer';
export function getFromSessionStorage(key) {
    let retVal = sessionStorage.getItem(key);
    if (isNotNullAndUndefined(retVal))
        return JSON.parse(retVal);
    return null;
}

export function backDropToggle() {
    const transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop !== null) { transparentBackdrop.classList.toggle("show"); };

    const sidenav = document.querySelector(".sidebar-nav");
    if (sidenav !== null) { sidenav.classList.remove("mini-navbar"); };

    const sidenavMenu = document.querySelector(".rpm-mp-menu");
    if (sidenavMenu !== null) { sidenavMenu.classList.toggle("mini-navbar"); };

    const listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader !== null) { listPageHeader.classList.remove("mini-navbar"); };

    const pageHeader = document.querySelector(".page-heading");
    if (pageHeader !== null) { pageHeader.classList.remove("mini-navbar"); };

    const detailsPageContent = document.querySelector(".detailsRightSection");
    if (detailsPageContent !== null) { detailsPageContent.classList.toggle("mini-navbar"); };

    const listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent !== null) { listPageContent.classList.remove("mini-navbar"); };

    const topNavbar = document.querySelector(".topNavBar ");
    if (topNavbar !== null) { topNavbar.classList.toggle("mini-navbar"); }

    const topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile !== null) { topNavMenuProfile.classList.remove("mini-navbar"); }

    const header = document.querySelector(".dealDetailsPage");
    if (header !== null) { header.classList.toggle("mini-navbar"); }

    const gridHeader = document.querySelector(".e-gridheader");
    if (gridHeader !== null) { gridHeader.classList.toggle("mini-navbar"); }
}


export function toggleSideMenu() {

    const transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop !== null) { transparentBackdrop.classList.toggle("show"); };

    const sidenav = document.querySelector(".sidebar-nav");
    if (sidenav !== null) { sidenav.classList.toggle("mini-navbar"); };
    const listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader !== null) { listPageHeader.classList.toggle("mini-navbar"); };

    const listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent !== null) { listPageContent.classList.toggle("mini-navbar"); };

    const detailsPageContent = document.querySelector(".detailsPanel");
    if (detailsPageContent !== null) { detailsPageContent.classList.toggle("mini-navbar"); };

    const header = document.querySelector(".page-heading");
    if (header !== null) { header.classList.toggle("mini-navbar"); }

    const footer = document.querySelector(".page-footer");
    if (footer !== null) { footer.classList.toggle("mini-navbar"); }

    const topNavbar = document.querySelector(".topNavBar ");
    if (topNavbar !== null) { topNavbar.classList.toggle("mini-navbar"); }

    const topNavMenu = document.querySelector(".topNavMenu ");
    if (topNavMenu !== null) { topNavMenu.classList.toggle("mini-navbar"); }

    const topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile !== null) { topNavMenuProfile.classList.toggle("mini-navbar"); }

}


export function toggleLeftSideMenu() {
    const transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop !== null) {
        transparentBackdrop.classList.toggle("show");
    }

    const sidenav = document.querySelector(".sidebar-nav");
    if (sidenav !== null) {
        sidenav.classList.remove("mini-navbar");
    }

    const listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader !== null) {
        listPageHeader.classList.remove("mini-navbar");
    }

    const listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent !== null) {
        listPageContent.classList.remove("mini-navbar");
    }
}

export function handleScroll() {
    const wScroll = window.scrollY;
    toggleonScroll(wScroll);
};



export function toggleonScroll(wScroll) {
    const sidenav = document.querySelector(".sidebar-nav");
    const topNavbar = document.querySelector(".topNavBar ");
    const topNavMenubar = document.querySelector(".topNavMenu ");
    const listHeader = document.querySelector(".listPageHeader ");
    const detailsMobileSection = document.querySelector(".details-mobile-section");
    const detailsRightSection = document.querySelector(".details-right-section.active");
    const pageHeader = document.querySelector(".page-heading");
    const pageFooter = document.querySelector(".page-footer");
    const mainpageContainer = document.querySelector(".mainpage-container");

    if (wScroll > 30) {
        if (sidenav !== null) {
            if (sidenav.classList.contains(".mini-navbar")) {
                sidenav.classList.add("mini-navbar");
                sidenav.classList.add("scrolling-navbar");
                sidenav.classList.add("top-nav-collapse");
            }
            else {
                sidenav.classList.add("scrolling-navbar");
                sidenav.classList.add("top-nav-collapse");
            }
        }
        if (detailsMobileSection !== null) {
            if (detailsMobileSection.classList.contains(".scrolling-navbar")) {
                detailsMobileSection.classList.add("scrolling-navbar");
            }
            else {
                detailsMobileSection.classList.add("scrolling-navbar");
            }
        }
        if (detailsRightSection !== null) {
            if (detailsRightSection.classList.contains(".scrolling-navbar")) {
                detailsRightSection.classList.add("scrolling-navbar");
            }
            else {
                detailsRightSection.classList.add("scrolling-navbar");
            }
        }
        if (topNavbar !== null) {
            if (topNavbar.classList.contains(".mini-navbar")) {
                topNavbar.classList.add("mini-navbar");
                topNavbar.classList.add("scrolling-navbar");
                topNavbar.classList.add("top-nav-collapse");
            }
            else {
                topNavbar.classList.add("scrolling-navbar");
                topNavbar.classList.add("top-nav-collapse");
            }
        }
        if (topNavMenubar !== null) {
            if (topNavMenubar.classList.contains(".mini-navbar")) {
                topNavMenubar.classList.add("mini-navbar");
                topNavMenubar.classList.add("scrolling-navbar");
                topNavMenubar.classList.add("top-nav-collapse");
            }
            else {
                topNavMenubar.classList.add("scrolling-navbar");
                topNavMenubar.classList.add("top-nav-collapse");
            }
        }
        if (listHeader !== null) {
            if (listHeader.classList.contains(".mini-navbar")) {
                listHeader.classList.add("mini-navbar");
                listHeader.classList.add("scrolling-navbar top-nav-collapse");
                listHeader.classList.add("top-nav-collapse");
            }
            else {
                listHeader.classList.add("scrolling-navbar");
                listHeader.classList.add("top-nav-collapse");
            }
        }
        if (pageHeader !== null) {
            if (pageHeader.classList.contains(".mini-navbar")) {
                pageHeader.classList.add("mini-navbar");
                pageHeader.classList.add("scrolling-navbar top-nav-collapse");
                pageHeader.classList.add("top-nav-collapse");
            }
            else {
                pageHeader.classList.add("scrolling-navbar");
                pageHeader.classList.add("top-nav-collapse");
            }
        }
        if (pageFooter !== null) {
            if (pageFooter.classList.contains(".mini-navbar")) {
                pageFooter.classList.add("mini-navbar");
                pageFooter.classList.add("scrolling-navbar top-nav-collapse");
                pageFooter.classList.add("top-nav-collapse");
            }
            else {
                pageFooter.classList.add("scrolling-navbar");
                pageFooter.classList.add("top-nav-collapse");
            }
        }
        if (mainpageContainer !== null) {
            if (mainpageContainer.classList.contains(".mini-navbar")) {
                mainpageContainer.classList.add("mini-navbar");
                mainpageContainer.classList.add("scrolling-navbar top-nav-collapse");
                mainpageContainer.classList.add("top-nav-collapse");
            }
            else {
                mainpageContainer.classList.add("scrolling-navbar");
                mainpageContainer.classList.add("top-nav-collapse");
            }
        }
    }
    else if (wScroll < 30) {
        if (detailsMobileSection !== null) {
            if (detailsMobileSection.classList.contains(".mini-navbar")) {
                detailsMobileSection.classList.add("scrolling-navbar");
            }
            else {
                detailsMobileSection.classList.remove("scrolling-navbar");
            }
        }
        if (detailsRightSection !== null) {
            if (detailsRightSection.classList.contains(".mini-navbar")) {
                detailsRightSection.classList.add("scrolling-navbar");
            }
            else {
                detailsRightSection.classList.remove("scrolling-navbar");
            }
        }
        if (sidenav !== null) {
            if (sidenav.classList.contains(".mini-navbar")) {
                sidenav.classList.add("scrolling-navbar");
                sidenav.classList.add("top-nav-collapse");
            }
            else {
                sidenav.classList.remove("scrolling-navbar");
                sidenav.classList.remove("top-nav-collapse");
            }
        }
        if (topNavbar !== null) {
            if (topNavbar.classList.contains(".mini-navbar")) {
                topNavbar.classList.add("scrolling-navbar");
                topNavbar.classList.add("top-nav-collapse");
            }
            else {
                topNavbar.classList.remove("scrolling-navbar");
                topNavbar.classList.remove("top-nav-collapse");
            }
        }
        if (topNavMenubar !== null) {
            if (topNavMenubar.classList.contains(".mini-navbar")) {
                topNavMenubar.classList.add("scrolling-navbar");
                topNavMenubar.classList.add("top-nav-collapse");
            }
            else {
                topNavMenubar.classList.remove("scrolling-navbar");
                topNavMenubar.classList.remove("top-nav-collapse");
            }
        }
        if (listHeader !== null) {
            if (listHeader.classList.contains(".mini-navbar")) {
                listHeader.classList.add("scrolling-navbar");
                listHeader.classList.add("top-nav-collapse");
            }
            else {
                listHeader.classList.remove("scrolling-navbar");
                listHeader.classList.remove("top-nav-collapse");
            }
        }
        if (pageHeader !== null) {
            if (pageHeader.classList.contains(".mini-navbar")) {
                pageHeader.classList.add("scrolling-navbar");
                pageHeader.classList.add("top-nav-collapse");
            }
            else {
                pageHeader.classList.remove("scrolling-navbar");
                pageHeader.classList.remove("top-nav-collapse");
            }
        }
        if (pageFooter !== null) {
            if (pageFooter.classList.contains(".mini-navbar")) {
                pageFooter.classList.add("scrolling-navbar");
                pageFooter.classList.add("top-nav-collapse");
            }
            else {
                pageFooter.classList.remove("scrolling-navbar");
                pageFooter.classList.remove("top-nav-collapse");
            }
        }
        if (mainpageContainer !== null) {
            if (mainpageContainer.classList.contains(".mini-navbar")) {
                mainpageContainer.classList.add("scrolling-navbar");
                mainpageContainer.classList.add("top-nav-collapse");
            }
            else {
                mainpageContainer.classList.remove("scrolling-navbar");
                mainpageContainer.classList.remove("top-nav-collapse");
            }
        }
    }
}


export function toggleLeftSideMenuLinkClicked() {
    const sidenav = document.querySelector(".sidebar-nav");
    if (sidenav !== null) { sidenav.classList.remove("mini-navbar"); };

    const transparentBackdrop = document.querySelector(".transparentBackdrop");
    if (transparentBackdrop !== null) { transparentBackdrop.classList.remove("show"); };

    const listPageHeader = document.querySelector(".listPageHeader");
    if (listPageHeader !== null) { listPageHeader.classList.remove("mini-navbar"); };

    const listPageContent = document.querySelector(".page-wrapper-Container");
    if (listPageContent !== null) { listPageContent.classList.remove("mini-navbar"); };

    const topNavMenuProfile = document.querySelector(".topNavMenuProfile ");
    if (topNavMenuProfile !== null) { topNavMenuProfile.classList.remove("mini-navbar"); }

    const topNavbar = document.querySelector(".topNavBar ");
    if (topNavbar !== null) { topNavbar.classList.remove("mini-navbar"); }
}


export function GetValueJsonFromTextArea(value: string): ValueJson[] {
    let outVal: ValueJson[] = [];
    if (isNotNullAndUndefined(value)) {
        let valueArray = value.split('\n');
        valueArray.forEach((item, index) => {
            outVal.push({ Id: index + 1, Name: ConvertSystemName(item), DisplayName: item });
        });
    }
    return outVal;
}


export function GetTextFromValueJson(value: ValueJson[]): string {
    let outVal = '';
    if (isNotNullAndUndefined(value)) {
        value.forEach(function (item, index) {
            if (index !== value.length - 1)
                outVal = outVal + item.DisplayName + '\n';
            else
                outVal = outVal + item.DisplayName;
        });
    }
    return outVal;
}

export function GetValueJsonFromDropdown(label: string, value: string): ValueJson[] {
    let outVal: ValueJson[] = [];
    if (isNotNullAndUndefined(value) && isNotNullAndUndefined(label)) {
        outVal.push({ Id: 1, Name: ConvertSystemName(value), DisplayName: label });
    }
    return outVal;
}


export function GetDropdownFromValueJson(value: ValueJson[]): string {
    let outVal = '';
    if (isNotNullAndUndefined(value)) {
        value.forEach((item) => {
            outVal = outVal + item.Name;
        });
    }
    return outVal;
}

export function GetDropdownFromValue(value: string): string {
    let outVal = '';
    if (isNotNullAndUndefined(value)) {
        outVal = outVal + value;
    }
    return outVal;
}

export function GetRAFComponentTypeByRAFDataType(dataType: string, uiType: string): RAFComponentType {
    let retVal = RAFComponentType.TextField;
    switch (dataType) {
        case RAFDataType.Text: retVal = RAFComponentType.TextField; break;
        case RAFDataType.Number: retVal = RAFComponentType.Number; break;
        case RAFDataType.Boolean: retVal = RAFComponentType.Checkbox; break;
        case RAFDataType.Date: retVal = RAFComponentType.Datetime; break;
        case RAFDataType.Dropdown:
            switch (uiType) {
                case RAFUIType.RadioButton:
                    retVal = RAFComponentType.Radio; break;
                default:
                    retVal = RAFComponentType.Select; break;
            }
            break;
        case RAFDataType.Lookup:
        case "custom":
            retVal = RAFComponentType.Select; break;
        default: break;
    }
    return retVal;
}

export function AddComponentsToPanelComponent(inVal: RAFComponentRow[], panelIndex: number, title: string): RAFComponentRow[] {
    let outVal: RAFComponentRow[] = [];
    if (isNotNullAndUndefined(inVal)) {

        let panelComponent: RAFComponentRow = {};
        panelComponent.input = false;
        panelComponent.key = "panel" + panelIndex;
        panelComponent.label = title;
        panelComponent.hideLabel = false;
        panelComponent.collapsible = true;
        panelComponent.collapsed = false;
        panelComponent.type = RAFComponentType.Panel;
        panelComponent.data = {};
        panelComponent.components = inVal;
        outVal.push(panelComponent);

    }
    return outVal;
}

export function getDataByKey(data, key) {
    if (isNotNullAndUndefined(data))
        return data[key];
    else
        return undefined;
}

export function ConvertSchedulerToCronExpression(r) {
    //r = r.replace("RRULE:", "");
    const INCASE_NOT_SUPPORTED = "NOT-SUPPORTED";
    const C_DAYS_OF_WEEK_RRULE = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
    const C_DAYS_WEEKDAYS_RRULE = ["MO", "TU", "WE", "TH", "FR"];
    const C_DAYS_OF_WEEK_CRONE = ["2", "3", "4", "5", "6", "7", "1"];
    const C_DAYS_OF_WEEK_CRONE_NAMED = [
        "MON",
        "TUE",
        "WED",
        "THU",
        "FRI",
        "SAT",
        "SUN"
    ];
    const C_MONTHS = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
    ];
    let result = "";
    let dayTime = "0 0 0";
    let dayOfMonth = "?";
    let month = "*";
    let dayOfWeek = "?";
    let year = "*";

    let FREQ = "";
    let INTERVAL = -1;
    let BYMONTHDAY = -1;
    let BYMONTH = -1;
    let BYDAY = "";
    let BYSETPOS = 0;

    let rarr;
    if (isNotNullAndUndefined(r)) {
        rarr = r.split(";");
    }
    for (let i = 0; i < rarr.length; i++) {
        let param = rarr[i].split("=")[0];
        let value = rarr[i].split("=")[1];
        if (param === "FREQ") FREQ = value;
        if (param === "INTERVAL") INTERVAL = parseInt(value);
        if (param === "BYMONTHDAY") BYMONTHDAY = parseInt(value);
        if (param === "BYDAY") BYDAY = value;
        if (param === "BYSETPOS") BYSETPOS = parseInt(value);
        if (param === "BYMONTH") BYMONTH = parseInt(value);
    }
    if (FREQ === "MONTHLY") {
        if (INTERVAL === 1) {
            month = "*"; // every month
        } else {
            month = "1/" + INTERVAL; // 1 - start of january, every INTERVALth month
        }
        if (BYMONTHDAY !== -1) {
            dayOfMonth = BYMONTHDAY.toString();
        } else if (BYSETPOS !== 0) {
            if (BYDAY === "") {
                //console.error("No BYDAY specified for MONTHLY/BYSETPOS rule");
                return INCASE_NOT_SUPPORTED;
            }

            if (BYDAY === "MO,TU,WE,TH,FR") {
                if (BYSETPOS === 1) {
                    // First weekday of every month
                    // "FREQ=MONTHLY;INTERVAL=1;BYSETPOS=1;BYDAY=MO,TU,WE,TH,FR",
                    dayOfMonth = "1W";
                } else if (BYSETPOS === -1) {
                    // Last weekday of every month
                    // "FREQ=MONTHLY;INTERVAL=1;BYSETPOS=-1;BYDAY=MO,TU,WE,TH,FR",
                    dayOfMonth = "LW";
                } else {
                    // console.error(
                    //     "Unsupported Xth weekday for MONTHLY rule (only 1st and last weekday are supported)"
                    // );
                    return INCASE_NOT_SUPPORTED;
                }
            } else if (C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY) === -1) {
                // console.error(
                //     "Unsupported BYDAY rule (multiple days are not supported by crone): " +
                //     BYDAY
                // );
                return INCASE_NOT_SUPPORTED;
            } else {
                dayOfMonth = "?";
                if (BYSETPOS > 0) {
                    // 3rd friday = BYSETPOS=3;BYDAY=FR in RRULE, 6#3
                    dayOfWeek =
                        C_DAYS_OF_WEEK_CRONE[C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY)] +
                        "#" +
                        BYSETPOS.toString();
                } else {
                    // last specific day
                    dayOfWeek =
                        C_DAYS_OF_WEEK_CRONE[C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY)] + "L";
                }
            }
        } else {
            // console.error("No BYMONTHDAY or BYSETPOS in MONTHLY rrule");
            return INCASE_NOT_SUPPORTED;
        }
    }

    if (FREQ === "WEEKLY") {
        if (INTERVAL !== 1) {
            //    console.error ("every X week different from 1st is not supported");
            return INCASE_NOT_SUPPORTED;
        }
        if (
            BYDAY.split(",")
                .sort()
                .join(",") ===
            C_DAYS_OF_WEEK_RRULE.concat()
                .sort()
                .join(",")
        ) {
            dayOfWeek = "*"; // all days of week
        } else {
            let arrByDayRRule = BYDAY.split(",");
            let arrByDayCron = [];
            for (let i = 0; i < arrByDayRRule.length; i++) {
                let indexOfDayOfWeek = C_DAYS_OF_WEEK_RRULE.indexOf(arrByDayRRule[i]);
                arrByDayCron.push(C_DAYS_OF_WEEK_CRONE_NAMED[indexOfDayOfWeek]);
            }
            dayOfWeek = arrByDayCron.join(",");
        }
    }

    if (FREQ === "DAILY") {
        if (INTERVAL !== 1) {
            dayOfMonth = "1/" + INTERVAL.toString();
        }
    }

    if (FREQ === "YEARLY") {
        if (BYMONTH === -1) {
            // console.error("Missing BYMONTH in YEARLY rule");
            return INCASE_NOT_SUPPORTED;
        }
        month = C_MONTHS[BYMONTH - 1];
        if (BYMONTHDAY !== -1) {
            // FREQ=YEARLY;BYMONTH=3;BYMONTHDAY=2  // 2nd day of March
            dayOfMonth = BYMONTHDAY.toString();
        } else {
            if (BYSETPOS === -1) {
                if (
                    BYDAY.split(",")
                        .sort()
                        .join(",") ===
                    C_DAYS_OF_WEEK_RRULE.concat()
                        .sort()
                        .join(",")
                ) {
                    dayOfMonth = "L";
                } else if (
                    BYDAY.split(",")
                        .sort()
                        .join(",") ===
                    C_DAYS_WEEKDAYS_RRULE.concat()
                        .sort()
                        .join(",")
                ) {
                    dayOfMonth = "LW";
                } else {
                    // console.error(
                    //     "Last weekends and just last specific days of Month are not supported"
                    // );
                    return INCASE_NOT_SUPPORTED;
                }
            } else {
                if (
                    BYDAY.split(",")
                        .sort()
                        .join(",") ===
                    C_DAYS_WEEKDAYS_RRULE.concat()
                        .sort()
                        .join(",") &&
                    BYSETPOS === 1
                ) {
                    dayOfMonth = BYSETPOS.toString() + "W";
                } else if (BYDAY.split(",").length === 1) {
                    dayOfWeek =
                        C_DAYS_OF_WEEK_CRONE[C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY)] +
                        "#" +
                        BYSETPOS.toString();
                } else {
                    // console.error("Multiple days are not supported in YEARLY rule");
                    return INCASE_NOT_SUPPORTED;
                }
            }
        }
    }

    result = `${dayTime} ${dayOfMonth} ${month} ${dayOfWeek} ${year}`;
    return result;
}

export const getEntitiesByModuleName = (moduleName) => {
    let url = `Entity/DataList`;
    const moduleRequest: ModuleListRequest = new ModuleListRequest();
    if (isNotNullAndUndefined(moduleName)) {
        moduleRequest.ModuleName = moduleName;
    }
    return new Promise<ModuleRow[]>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            moduleRequest,
            null,
            ContentType.applicationJson,
        ).then((response) => {
            const entityRows: EntityRow[] = response.data.Entities;
            resolve(entityRows);
        });

    });
};

export const getSimpleEntityByObjectName = (objectData) => {
    return new Promise<EntitySimpleJM>((resolve) => {
        if (isNotNullAndUndefined(objectData) &&
            (isNotNullAndUndefined(objectData.ObjectName) || isNotNullAndUndefined(objectData.EntityId))) {
            const simpleEntityStorage = isNotNullAndUndefined(objectData.ObjectName) ?
                getSessionStorage(StorageKey.simpleEntity_modulename + objectData.ObjectName, true) : null;
            let entityRow: EntitySimpleJM = isNotNullAndUndefined(simpleEntityStorage) &&
                !IsNullOrWhiteSpace(simpleEntityStorage) &&
                isNotNullAndUndefined(JSON.parse(simpleEntityStorage)) ? JSON.parse(simpleEntityStorage) : null;
            if (isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID)) {
                resolve(entityRow);
            } else {
                let url = `Entity/RetrieveSimple`;
                return repositoryActions.postDataAndGetResponse(
                    url,
                    objectData,
                    null,
                    ContentType.applicationJson, false
                ).then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                        setSessionStorage(StorageKey.simpleEntity_modulename + objectData.ObjectName, true, JSON.stringify(response.data.Entity));
                        resolve(response.data.Entity);
                    } else {
                        resolve(null);
                    }
                });
            }
        } else {
            resolve(null);
        }
    });
};

export async function getEntityByObjectName(objectData): Promise<EntityRow> {
    const isOnline = await isConnectedToInternet();
    //if (isOnline === true) {
    return getEntityByObjectNameAPI(objectData);
    // }
    // else {
    //     return getEntityByObjectNameFB(objectData);
    // }
}

export const getEntityByObjectNameAPI = (objectData) => {
    return new Promise<EntityRow>((resolve) => {
        if (isNotNullAndUndefined(objectData) &&
            (isNotNullAndUndefined(objectData.ObjectName) || isNotNullAndUndefined(objectData.EntityId))) {
            const simpleEntityStorage = isNotNullAndUndefined(objectData.ObjectName) ?
                getSessionStorage(StorageKey.entity_modulename + objectData.ObjectName, true) : null;
            let entityRow: EntityRow = isNotNullAndUndefined(simpleEntityStorage) &&
                !IsNullOrWhiteSpace(simpleEntityStorage) &&
                isNotNullAndUndefined(JSON.parse(simpleEntityStorage)) ? JSON.parse(simpleEntityStorage) : null;
            if (isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID)) {
                resolve(entityRow);
            } else {
                let url = `Entity/Retrieve`;
                return repositoryActions.postDataAndGetResponse(
                    url,
                    objectData,
                    null,
                    ContentType.applicationJson, false
                ).then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                        setSessionStorage(StorageKey.entity_modulename + objectData.ObjectName, true, JSON.stringify(response.data.Entity));
                        resolve(response.data.Entity);
                    } else {
                        resolve(null);
                    }
                });
            }
        } else {
            resolve(null);
        }
    });
};

export async function getEntityByObjectNameFB(objectData) {
    //const REACT_APP_FB_SUFFIX: string = isNotNullAndUndefined(process.env.REACT_APP_FB_SUFFIX) ? process.env.REACT_APP_FB_SUFFIX : "";
    const REACT_APP_FB_SUFFIX = getFBSuffix();
    let entity: EntityRow = null;

    const app = initializeApp(firebaseConfig);
    const firestoreDatabase = initializeFirestore(app, {
        ignoreUndefinedProperties: true,
    });

    const q = query(collection(firestoreDatabase, `tenants${REACT_APP_FB_SUFFIX}/${msalInstance.currentTenantId}/entities`), where("EntityName", "==", objectData.ObjectName), limit(1));
    const entitiesSnapshot = await getDocs(q);
    entitiesSnapshot.forEach((doc1) => {
        entity = {
            ...doc1.data(),
            //CreatedDate: doc1.get(propertyOf<UserFeedRow>("CreatedDate")).toDate()
        } as EntityRow;
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc1);
        //console.log(doc1.id, " => ", doc1.data());
    });

    return entity;
}

export const getAllModulesByModuleName = (moduleName?: string, isAdmin?: boolean) => {
    let url;
    if (isNotNullAndUndefined(isAdmin) && isAdmin === true) {
        url = `${moduleName}/ListAll`;
    }
    else {
        url = `${moduleName}/List`;
    }
    return new Promise<ModuleRow[]>((resolve) => {
        const allViewsStorage = getSessionStorage(StorageKey.allViews_modulename + moduleName, true);

        if (isNotNullAndUndefined(allViewsStorage) && !IsNullOrWhiteSpace(allViewsStorage)) {
            const allViews: ModuleRow[] = JSON.parse(allViewsStorage);
            if (isNotNullAndUndefined(allViews)) {
                resolve(allViews);
            }
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                null,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                const allViews: ModuleRow[] = response.data.Entities;
                setSessionStorage(StorageKey.allViews_modulename + moduleName, true, JSON.stringify(allViews));
                resolve(allViews);
            });
        }
    });
};
export const convertfilterModel = (attributes: AttributeRow[]): ColumnsModel[] => {
    let retVal: ColumnsModel[] = [];
    if (isNotNullAndUndefined(attributes)) {
        for (var i = 0; i < attributes.length; i++) {
            const attrib = attributes[i];
            let obj: ColumnsModel = {};
            obj.field = attrib.Name;
            obj.label = attrib.DisplayName;
            obj.type = getSFColumnTypeByRAFDatatype(attrib.DataType);
            retVal.push(obj);
        }
    }
    return retVal;
};


export const getEntities = () => {
    let url = `Entity/List`;
    let moduleListRequest = new ModuleListRequest();
    moduleListRequest.EntityType = RAFEntityType.DataList;

    let sortBy = {
        field: propertyOf<EntityRow>("EntityName"),
        order: "ascending",
    };
    let sortQuery: string[] = [];
    sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );

    moduleListRequest.Sort = sortQuery;

    return new Promise<EntityRow[]>((resolve) => {
        const entitiesStorage = getSessionStorage(RMStorageKey.entities);
        if (isNotNullAndUndefined(entitiesStorage) && !IsNullOrWhiteSpace(entitiesStorage)) {
            const entities: EntityRow[] = JSON.parse(entitiesStorage);
            if (isNotNullAndUndefined(entities)) {
                resolve(entities);
            }
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                moduleListRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                const entities: EntityRow[] = response.data.Entities;
                setSessionStorage(RMStorageKey.entities, false, JSON.stringify(entities));
                resolve(entities);
            })
                ;
        }

    });
};

export async function getAllEntities(): Promise<EntityRow[]> {
    const isOnline = await isConnectedToInternet();
    //if (isOnline === true) {
    return getAllEntitiesAPI();
    // }
    // else {
    //     return getAllEntitiesFB();
    // }
}

export const getAllEntitiesAPI = () => {
    //let url = `Entity/ListAll`;
    let url = `Entity/List`;

    const moduleRequest: ModuleListRequest = new ModuleListRequest();

    let sortBy = {
        field: propertyOf<EntityRow>("EntityName"),
        order: "ascending",
    };
    let sortQuery: string[] = [];
    sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );

    moduleRequest.Sort = sortQuery;

    return new Promise<EntityRow[]>((resolve) => {
        const allEntitiesStorage = getSessionStorage(RMStorageKey.all_Entities, true);
        if (isNotNullAndUndefined(allEntitiesStorage) && !IsNullOrWhiteSpace(allEntitiesStorage) && isNotNullAndUndefined(JSON.parse(allEntitiesStorage))) {
            const allEntities: EntityRow[] = JSON.parse(allEntitiesStorage);
            resolve(allEntities);
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                moduleRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)) {
                    const allEntitiesList: EntityRow[] = response.data.Entities;
                    if (isNotNullAndUndefined(allEntitiesList) && allEntitiesList.length > 0) {
                        setSessionStorage(RMStorageKey.all_Entities, true, JSON.stringify(allEntitiesList));
                    }
                    resolve(allEntitiesList);
                } else {
                    resolve(null);
                }
            })
                ;
        }

    });
};

export const getAllDocumentEnableForms = () => {
    return new Promise<FormLibraryRow[]>(async (resolve) => {
        const allForms = await getAllForms();
        const allEntities = await getAllEntities();
        let allDocumetForms: FormLibraryRow[] = [];
        let userForms = isNotEmptyArray(allForms) ? allForms.filter(x => x.FormType === RAFFormType.UserForm) : [];
        isNotEmptyArray(allEntities) && isNotEmptyArray(userForms) && userForms.forEach(item => {
            let relatedEntity = allEntities.find(x => x.EntityName === item.Entity);
            if (isNotNullAndUndefined(relatedEntity) && isNotNullAndUndefined(relatedEntity.UID) && isNotNullAndUndefined(relatedEntity.EntitySettings) && relatedEntity.EntitySettings.EnableDocument === true) {
                allDocumetForms.push(item);
            }
        });

        resolve(allDocumetForms);
    });
};

export const getAllProcessEnabledForms = () => {
    return new Promise<FormLibraryRow[]>(async (resolve) => {
        const allForms = await getAllForms();
        const allEntities = await getAllEntities();
        let allProcessEnabledForms: FormLibraryRow[] = [];
        let userForms = isNotEmptyArray(allForms) ? allForms.filter(x => x.FormType === RAFFormType.UserForm) : [];
        isNotEmptyArray(allEntities) && isNotEmptyArray(userForms) && userForms.forEach(item => {
            let relatedEntity = allEntities.find(x => x.EntityName === item.Entity);
            if (isNotNullAndUndefined(relatedEntity) && isNotNullAndUndefined(relatedEntity.UID) && isNotNullAndUndefined(relatedEntity.EntitySettings) && relatedEntity.EntitySettings.EnableProcess === true) {
                allProcessEnabledForms.push(item);
            }
        });

        resolve(allProcessEnabledForms);
    });
};

export async function getAllEntitiesFB() {
    //const REACT_APP_FB_SUFFIX: string = isNotNullAndUndefined(process.env.REACT_APP_FB_SUFFIX) ? process.env.REACT_APP_FB_SUFFIX : "";
    const REACT_APP_FB_SUFFIX = getFBSuffix();
    let entityList: EntityRow[] = [];
    const app = initializeApp(firebaseConfig);
    const firestoreDatabase = initializeFirestore(app, {
        ignoreUndefinedProperties: true,
    });
    //const q = query(collection(firestoreDatabase, `users${REACT_APP_FB_SUFFIX}/${msalInstance.currentUserId}/UserFeeds`), where("IsRead", "==", true), orderBy("CreatedDate", "desc"));
    const entitiesCol = collection(firestoreDatabase, `tenants${REACT_APP_FB_SUFFIX}/${msalInstance.currentTenantId}/entities`);
    const entitiesSnapshot = await getDocs(entitiesCol);
    entitiesSnapshot.forEach((doc1) => {
        entityList.push({
            ...doc1.data(),
            //CreatedDate: doc1.get(propertyOf<EntityRow>("CreatedDate")).toDate()
        } as EntityRow);
    });

    return entityList;
}

export const getAllEntitiesByType = (entityType?: string, isAdmin?: boolean) => {
    let url = `Entity/ListAll`;
    if (isNotNullAndUndefined(isAdmin) && isAdmin === true) {
        url = `Entity/ListAll`;
    }
    else {
        url = `Entity/List`;
    }
    const moduleRequest: ModuleListRequest = new ModuleListRequest();

    if (isNotNullAndUndefined(entityType)) {
        moduleRequest.EntityType = entityType && entityType === "allmodule" ? null : entityType;
    }

    let sortBy = {
        field: propertyOf<EntityRow>("EntityName"),
        order: "ascending",
    };
    let sortQuery: string[] = [];
    sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );

    moduleRequest.Sort = sortQuery;

    return new Promise<EntityRow[]>((resolve) => {
        const allEntitiesStorage = getSessionStorage(RMStorageKey.allEntities_entityType + entityType);
        if (isNotNullAndUndefined(allEntitiesStorage) && !IsNullOrWhiteSpace(allEntitiesStorage)) {
            const allEntities: EntityRow[] = JSON.parse(allEntitiesStorage);
            if (isNotNullAndUndefined(allEntities)) {
                resolve(allEntities);
            }
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                moduleRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                const allEntitiesList: EntityRow[] = response.data.Entities;
                if (isNotNullAndUndefined(allEntitiesList) && allEntitiesList.length > 0) {
                    setSessionStorage(RMStorageKey.allEntities_entityType + entityType, false, JSON.stringify(allEntitiesList));
                }
                resolve(allEntitiesList);
            });

        }

    });
};

export const getAllRelatedEntities = (entityName: string) => {
    return new Promise<EntityRow[]>((resolve) => {
        if (isNotNullAndUndefined(entityName)) {
            let url = `Entity/RelatedEntities`;
            const moduleRequest: ModuleListRequest = new ModuleListRequest();
            if (isNotNullAndUndefined(entityName)) {
                moduleRequest.EntityName = entityName;
            }
            const allRelatedEntitiesStorage = getSessionStorage(RMStorageKey.allRelatedEntities_modulename + entityName);
            if (isNotNullAndUndefined(allRelatedEntitiesStorage) && !IsNullOrWhiteSpace(allRelatedEntitiesStorage)) {
                const allRelatedEntities: EntityRow[] = JSON.parse(allRelatedEntitiesStorage);
                //if (isNotNullAndUndefined(allRelatedEntities)) {
                resolve(allRelatedEntities);
                //}
            }
            else {
                return repositoryActions.postDataAndGetResponse(
                    url,
                    moduleRequest,
                    null,
                    ContentType.applicationJson, false
                ).then((response) => {
                    const allRelatedEntitiesList: EntityRow[] = response.data.Entities;
                    //if (isNotNullAndUndefined(allRelatedEntitiesList)) {
                    setSessionStorage(RMStorageKey.allRelatedEntities_modulename + entityName, false, JSON.stringify(allRelatedEntitiesList));
                    //}
                    resolve(allRelatedEntitiesList);
                });

            }
        } else {
            resolve(null);
        }
    });
};

export const getRelatedEntitiesByName = (entityName: string) => {
    return new Promise<EntityRow[]>((resolve) => {
        if (isNotNullAndUndefined(entityName)) {
            let url = `Entity/RelatedEntities`;
            const moduleRequest: ModuleListRequest = new ModuleListRequest();
            if (isNotNullAndUndefined(entityName)) {
                moduleRequest.EntityName = entityName;
                //moduleRequest.EntityType = RAFEntityType.DataList;
            }

            return repositoryActions.postDataAndGetResponse(
                url,
                moduleRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                const allRelatedEntitiesList: EntityRow[] = response.data.Entities;
                resolve(allRelatedEntitiesList);
            });

        } else {
            resolve(null);
        }
    });
};

export const getAllRelatedAttributes = (entityName: string) => {
    return new Promise<AttributeRow[]>((resolve) => {
        if (isNotNullAndUndefined(entityName)) {
            let url = `Attribute/RelatedAttributes`;
            const moduleRequest: ModuleListRequest = new ModuleListRequest();
            if (isNotNullAndUndefined(entityName)) {
                moduleRequest.EntityName = entityName;
            }
            const allRelatedAttributesStorage = getSessionStorage(RMStorageKey.allRelatedAttributes_modulename + entityName);
            if (isNotNullAndUndefined(allRelatedAttributesStorage) && !IsNullOrWhiteSpace(allRelatedAttributesStorage)) {
                const allRelatedAttributes: AttributeRow[] = JSON.parse(allRelatedAttributesStorage);
                //if (isNotNullAndUndefined(allRelatedAttributes)) {
                resolve(allRelatedAttributes);
                //}
            }
            else {
                return repositoryActions.postDataAndGetResponse(
                    url,
                    moduleRequest,
                    null,
                    ContentType.applicationJson, false
                ).then((response) => {
                    const allRelatedAttributesList: AttributeRow[] = response.data.Entities;
                    setSessionStorage(RMStorageKey.allRelatedAttributes_modulename + entityName, false, JSON.stringify(allRelatedAttributesList));
                    resolve(allRelatedAttributesList);
                });

            }
        } else {
            resolve(null);
        }
    });
};


export const GetEmailTemplateByModule = (moduleName?: string) => {
    let listServiceRequest = new ListServiceRequest();
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push(moduleName);
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = propertyOf<TemplateRow>("Entity");
    customFilter.Rules.push(filter);

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push('Email');
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<TemplateRow>("TemplateType");
    customFilter.Rules.push(filter1);
    listServiceRequest.CustomFilter = customFilter;
    //listServiceRequest.ViewName = 'all_templates';

    let sortBy = {
        field: propertyOf<TemplateRow>("TemplateName"),
        order: "ascending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
        sortQuery.push(
            sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
        );
    }
    listServiceRequest.Sort = sortQuery;

    return new Promise<TemplateRow[]>((resolve) => {
        repositoryActions.postDataAndGetResponse('Template/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            });
    });
};

export const GetSignatureTemplateByModule = (moduleName?: string) => {
    const loggedinUserId = msalInstance.currentUserId;
    let listServiceRequest = new ListServiceRequest();
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push(moduleName);
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = propertyOf<TemplateRow>("Entity");
    customFilter.Rules.push(filter);

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push('Signature');
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<TemplateRow>("TemplateType");
    customFilter.Rules.push(filter1);
    listServiceRequest.CustomFilter = customFilter;

    let filter2: RAFCustomFilter = {};
    let filterVal2: string[] = [];
    filterVal2.push(loggedinUserId);
    filter2.Operator = RAFCustomOperator.Equal;
    filter2.Value = filterVal2;
    filter2.Field = propertyOf<TemplateRow>("UserUID");
    customFilter.Rules.push(filter2);
    listServiceRequest.CustomFilter = customFilter;
    //listServiceRequest.ViewName = 'all_templates';

    let sortBy = {
        field: propertyOf<TemplateRow>("TemplateName"),
        order: "ascending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
        sortQuery.push(
            sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
        );
    }
    listServiceRequest.Sort = sortQuery;

    return new Promise<TemplateRow[]>((resolve) => {
        repositoryActions.postDataAndGetResponse('Template/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            });
    });
};

export const WorkspaceTemplateColumnTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "wsTemplate" },
];

export const ProcessTemplateColumnTemplates: GridColumnTemplates[] = [
    //{ key: "ProcessName", value: "bpTemplate" },
    // { key: "ProcessName", value: "bpRowTemplate" },
    { key: "TagsListJson", value: "tagsTemplate" },
    { key: "StartMode", value: "dropdownTemplate" },
    { key: "ModifiedDate", value: "updatedDateTemplate" },
    { key: "CreatedDate", value: "dateTimeTemplate" },
    { key: "LastActivityDate", value: "dateTimeTemplate" },
];

export const ProcessTemplateListGridColumnTemplates: GridColumnTemplates[] = [
    { key: "ProcessName", value: "bpRowTemplate" },
];

export const BookingListGridColumnTemplates: GridColumnTemplates[] = [
    { key: "Status", value: "statusDropdownTemplate" },
];

export const ProcessColumnTemplates: GridColumnTemplates[] = [
    { key: "ProcessName", value: "processTemplate" },
    { key: "StepCount", value: "progressBarTemplate" },
    { key: "DueDate", value: "dateTemplate" },
    { key: "StartDate", value: "dateTimeTemplate" },
    { key: "EndDate", value: "dateTimeTemplate" },
    { key: "ModifiedDate", value: "dateTimeTemplate" },
    { key: "CreatedDate", value: "dateTimeTemplate" },
    { key: "LastActivityDate", value: "dateTimeTemplate" },
];

export const formLibraryTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "formLibraryTemplate" },
];

export const WorkspaceTemplateColumnWidths: GridColumnWidth[] = [
    { field: "ModifiedDate", width: "250px" },
];
export const TimeSheetTemplateColumnWidths: GridColumnWidth[] = [
    { field: "ActualStartDate", width: "250px" },
];
export const uesrGroupColumnWidths: GridColumnWidth[] = [
    { field: "TeamName", width: "400px" },
    { field: "Description", width: "300px" },
];
export const ProcessColumnWidths: GridColumnWidth[] = [
    { field: "ProcessName", width: "400px" },
    { field: "Description", width: "300px" },
    { field: "DueDate", width: "150px" },
    { field: "StartDate", width: "150px" },
    { field: "ProcessStarter", width: "150px" },
    { field: "EndDate", width: "150px" },
    { field: "Entity", width: "150px" },
    { field: "ModifiedBy", width: "150px" },
    { field: "ModifiedDate", width: "150px" },
];

export const ProcessColumnHeaders: GridColumnHeader[] = [
    { field: "ProcessName", value: "Process Name" },
    { field: "DueDate", value: "Due on" },
    { field: "StartDate", value: "Started on" },
    { field: "ProcessStarter", value: "Started By" },
    { field: "EndDate", value: "Completed on" },
    { field: "Entity", value: "Module" },
    { field: "ModifiedBy", value: "Last Modified By" },
    { field: "ModifiedDate", value: "Last Modified" },
    { field: "ExecutionOrder", value: "Flow Order" },
    { field: "ProcessStatus", value: "Status" },
];

export const CompletedProcessColumnHeaders: GridColumnHeader[] = [
    { field: "ProcessName", value: "Process Name" },
    { field: "StartDate", value: "Started on" },
    { field: "ProcessStarter", value: "Started By" },
    { field: "EndDate", value: "Completed on" },
    { field: "ModifiedBy", value: "Completed By" },
];

export const ProcessTemplateColumnWidths: GridColumnWidth[] = [
    { field: "ProcessName", width: "300px" },
    { field: "Description", width: "250px" },
    { field: "FormName", width: "120px" },
    { field: "StartMode", width: "150px" },
    { field: "TagsListJson", width: "150px" },
    { field: "Entity", width: "150px" },
    { field: "ExecutionOrder", width: "150px" },
    { field: "LastActivityDate", width: "150px" },
    { field: "ModifiedBy", width: "120px" },
    { field: "ModifiedDate", width: "80px" },
];

export const ProcessTemplateColumnHeaders: GridColumnHeader[] = [
    { field: "ProcessName", value: "Process Name" },
    { field: "Entity", value: "Module" },
    { field: "ModifiedDate", value: "Updated" }
];

export const IncidentTemplateColumnHeaders: GridColumnHeader[] = [
    { field: "title", value: "Impact/Injury to Subject" },
    { field: "start_date", value: "Incident DateTime" },
    { field: "care_recipient", value: "Client" }
];

export const RiskTemplateColumnHeaders: GridColumnHeader[] = [
    { field: "title", value: "Potential Risk" },
    { field: "start_date", value: "Date" },
    { field: "care_recipient", value: "Client" }
];

export const ComplaintTemplateColumnHeaders: GridColumnHeader[] = [
    { field: "title", value: "Desired Outcomes" },
    { field: "start_date", value: "Date" },
    { field: "care_recipient", value: "Client" }
];

export const TemplateGridColumnHeaders: GridColumnHeader[] = [
    { field: "TemplateType", value: "Type" },
    { field: "Entity", value: "Module" },
    { field: "TemplateName", value: "Name" }
];

export const TemplateGridColumnTemplates: GridColumnTemplates[] = [
    { key: "ModifiedDate", value: "updatedDateTemplate" },
    { key: "TemplateType", value: "dropdownTemplate" },
];

export const BusinessRuleColumnTemplates: GridColumnTemplates[] = [
    { key: "RuleName", value: "bpRuleTemplate" },
    { key: "ModifiedDate", value: "updatedDateTemplate" },
];

export const FormLibraryColumnTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "formLibraryNameTemplate" },
];

export const FormLibraryListGridColumnTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "formLibraryDisplayNameTemplate" },
];


export const FormLibraryColumnWidths: GridColumnWidth[] = [
    { field: "Title", width: "400px" },
];

export const ProcessLibraryListGridColumnTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "processLibraryDisplayNameTemplate" },
];

export const ProcessRecordsGridColumnTemplates: GridColumnTemplates[] =
    // [{ key: "FormLibrary", value: "inspectionRecordLibraryNewDisplayNameTemplate" }];
    [{ key: "FormLibrarySchedule", value: "processRecordDisplayNameTemplate" }];


export const getEntityNameById = (id?: string) => {
    let url = `Entity/Retrieve`;
    return new Promise<string>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            { "EntityId": id },
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            const entityRow: EntityRow = response.data.Entity;
            resolve(entityRow.EntityName);
        });
    });
};

export const getEntityById = (id?: string) => {
    let url = `Entity/Retrieve`;
    return new Promise<EntityRow>((resolve) => {
        if (!IsNullOrWhiteSpace(id)) {
            return repositoryActions.postDataAndGetResponse(
                url,
                { "EntityId": id },
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const getEntityByName = (entityName?: string) => {
    let url = `Entity/Retrieve`;
    return new Promise<EntityRow>((resolve) => {
        if (isNotNullAndUndefined(entityName)) {
            return repositoryActions.postDataAndGetResponse(
                url,
                { "ObjectName": entityName },
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entity)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const CheckEntityExist = (entityName?: string) => {
    let url = `Entity/IsExist`;
    return new Promise<boolean>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            { "EntityName": entityName },
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                if (response.data)
                    resolve(true);
                else
                    resolve(false);
            }
            else {
                resolve(false);
            }
        });
    });
};

export const getEntityByObjectData = (objectData, returnNull?: boolean) => {
    return new Promise<EntityRow>((resolve) => {
        if (returnNull !== true) {
            let url = `Entity/Retrieve`;
            return repositoryActions.postDataAndGetResponse(
                url,
                objectData,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const getBusinessActionList = (category?: string) => {
    let url = `BusinessRule/ActionList`;
    let businessRuleListRequest = new BusinessRuleListRequest();
    if (isNotNullAndUndefined(category)) {
        businessRuleListRequest.Category = category;
    }
    return new Promise<BusinessAction[]>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            businessRuleListRequest,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            const businessActions: BusinessAction[] = response.data.Entities;
            resolve(businessActions);
        });
    });
};

export const getTriggerListByEntityName = (entityName?: string) => {
    let url = `BusinessRuleTrigger/ListByEntityName`;
    const entityListRequest: EntityListRequest = new EntityListRequest();
    entityListRequest.EntityName = entityName;
    return new Promise<BusinessRuleTriggerRow[]>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            entityListRequest,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                const businessTriggerList: BusinessRuleTriggerRow[] = response.data.Entities;
                resolve(businessTriggerList);
            }
            else {
                resolve(null);
            }

        });
    });
};

export function hasDuplicates(arr) {
    return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
}

export const getBPTemplatesByEntityId = (entityId?: string) => {
    const loggedinUserId = msalInstance.currentUserId;
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(entityId);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<BusinessProcessTemplateRow>("FormUID");
    relatedFilter.Rules.push(filter1);

    let relatedFilter1: RAFCustomFilter = {};
    relatedFilter1.Condition = "or";
    relatedFilter1.Rules = [];
    let filter2: RAFCustomFilter = {};
    //let filterVal2: string[] = [];
    //filterVal2.push(null);
    filter2.Operator = RAFCustomOperator.IsNull;
    //filter2.Value = filterVal1;
    filter2.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter2);

    let filter3: RAFCustomFilter = {};
    let filterVal3: string[] = [];
    filterVal3.push(loggedinUserId);
    filter3.Operator = RAFCustomOperator.Equal;
    filter3.Value = filterVal3;
    filter3.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter3);

    relatedFilter.Rules.push(relatedFilter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    //listServiceRequest.ViewName = 'all_process_templates';
    let sortBy = {
        field: propertyOf<BusinessProcessTemplateRow>("LastActivityDate"),
        order: "descending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
        sortQuery.push(
            sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
        );
    }
    listServiceRequest.Sort = sortQuery;

    return new Promise<BusinessProcessTemplateRow[]>((resolve) => {
        repositoryActions.postDataAndGetResponse('BusinessProcessTemplate/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entities);
                }
            });
    });
};

export const getBPTemplatesByFormId = (formId?: string) => {
    const loggedinUserId = msalInstance.currentUserId;
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(formId);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<BusinessProcessTemplateRow>("FormUID");
    relatedFilter.Rules.push(filter1);

    let relatedFilter1: RAFCustomFilter = {};
    relatedFilter1.Condition = "or";
    relatedFilter1.Rules = [];
    let filter2: RAFCustomFilter = {};
    //let filterVal2: string[] = [];
    //filterVal2.push(null);
    filter2.Operator = RAFCustomOperator.IsNull;
    //filter2.Value = filterVal1;
    filter2.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter2);

    let filter3: RAFCustomFilter = {};
    let filterVal3: string[] = [];
    filterVal3.push(loggedinUserId);
    filter3.Operator = RAFCustomOperator.Equal;
    filter3.Value = filterVal3;
    filter3.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter3);

    relatedFilter.Rules.push(relatedFilter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    //listServiceRequest.ViewName = 'all_process_templates';
    let sortBy = {
        field: propertyOf<BusinessProcessTemplateRow>("LastActivityDate"),
        order: "descending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
        sortQuery.push(
            sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
        );
    }
    listServiceRequest.Sort = sortQuery;

    return new Promise<BusinessProcessTemplateRow[]>((resolve) => {
        repositoryActions.postDataAndGetResponse('BusinessProcessTemplate/ListAll', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entities);
                }
            });
    });
};

export const getBPTemplatesByEntityUID = (entityUID?: string) => {
    const loggedinUserId = msalInstance.currentUserId;
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(entityUID);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<BusinessProcessTemplateRow>("EntityUID");
    relatedFilter.Rules.push(filter1);

    let relatedFilter1: RAFCustomFilter = {};
    relatedFilter1.Condition = "or";
    relatedFilter1.Rules = [];
    let filter2: RAFCustomFilter = {};
    //let filterVal2: string[] = [];
    //filterVal2.push(null);
    filter2.Operator = RAFCustomOperator.IsNull;
    //filter2.Value = filterVal1;
    filter2.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter2);

    let filter3: RAFCustomFilter = {};
    let filterVal3: string[] = [];
    filterVal3.push(loggedinUserId);
    filter3.Operator = RAFCustomOperator.Equal;
    filter3.Value = filterVal3;
    filter3.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter3);

    relatedFilter.Rules.push(relatedFilter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    //listServiceRequest.ViewName = 'all_process_templates';
    let sortBy = {
        field: propertyOf<BusinessProcessTemplateRow>("LastActivityDate"),
        order: "descending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
        sortQuery.push(
            sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
        );
    }
    listServiceRequest.Sort = sortQuery;

    return new Promise<BusinessProcessTemplateRow[]>((resolve) => {
        repositoryActions.postDataAndGetResponse('BusinessProcessTemplate/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entities);
                }
            });
    });
};

export const getBPTemplatesByEntityName = (entityName?: string, returnNull?: boolean) => {
    const loggedinUserId = msalInstance.currentUserId;
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(entityName);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<BusinessProcessTemplateRow>("FormName");
    relatedFilter.Rules.push(filter1);

    let filter2: RAFCustomFilter = {};
    let filterVal2: string[] = [];
    filterVal2.push('true');
    filter2.Operator = RAFCustomOperator.Equal;
    filter2.Value = filterVal2;
    filter2.Field = propertyOf<BusinessProcessTemplateRow>("IsActive");
    relatedFilter.Rules.push(filter2);

    // let filter3: RAFCustomFilter = {};
    // let filterVal3: string[] = [];
    // filterVal3.push(RAFBPCurrentStatus.Published);
    // filter3.Operator = RAFCustomOperator.Equal;
    // filter3.Value = filterVal1;
    // filter3.Field = propertyOf<BusinessProcessTemplateRow>("CurrentStatus");
    // relatedFilter.Rules.push(filter3);

    let relatedFilter1: RAFCustomFilter = {};
    relatedFilter1.Condition = "or";
    relatedFilter1.Rules = [];
    let filter4: RAFCustomFilter = {};
    filter4.Operator = RAFCustomOperator.IsNull;
    filter4.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter4);

    let filter5: RAFCustomFilter = {};
    let filterVal5: string[] = [];
    filterVal5.push(loggedinUserId);
    filter5.Operator = RAFCustomOperator.Equal;
    filter5.Value = filterVal5;
    filter5.Field = propertyOf<BusinessProcessTemplateRow>("InitiatorUID");
    relatedFilter1.Rules.push(filter5);

    relatedFilter.Rules.push(relatedFilter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    //listServiceRequest.ViewName = 'all_process_templates';
    let sortBy = {
        field: propertyOf<BusinessProcessTemplateRow>("LastActivityDate"),
        order: "descending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
        sortQuery.push(
            sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
        );
    }
    listServiceRequest.Sort = sortQuery;
    return new Promise<BusinessProcessTemplateRow[]>((resolve) => {
        if (returnNull !== true) {
            repositoryActions.postDataAndGetResponse('BusinessProcessTemplate/ListByModule', listServiceRequest, null, ContentType.applicationJson)
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }
                });
        } else {
            resolve(null);
        }
    });
};

export function getDisplayNameforRuleAction(action: string) {
    let ruleName = '';
    switch (action) {
        case RAFBusinessAction.showField:
            ruleName = 'Show Field ...';
            break;
        case RAFBusinessAction.hideField:
            ruleName = 'Hide Field ...';
            break;
        case RAFBusinessAction.setValue:
            ruleName = 'Set the value of ...';
            break;
        case RAFBusinessAction.clearValue:
            ruleName = 'Clear Value of ...';
            break;
        case RAFBusinessAction.setCurrentDateAs:
            ruleName = 'Set Current date';
            break;
        case RAFBusinessAction.setCurrentUserAs:
            ruleName = 'Set Current user';
            break;
        case RAFBusinessAction.makeRequired:
            ruleName = 'Make required ...';
            break;
        case RAFBusinessAction.showMessage:
            ruleName = 'Show message ...        ';
            break;
        case RAFBusinessAction.sendMail:
            ruleName = 'Send Mail ...';
            break;
        case RAFBusinessAction.sendSms:
            ruleName = 'Send SMS ...';
            break;
        case RAFBusinessAction.showStep:
            ruleName = 'Show Step ...';
            break;
        case RAFBusinessAction.hideStep:
            ruleName = 'Hide Step ...';
            break;
        case RAFBusinessAction.sendReport:
            ruleName = 'Send Report ...';
            break;
        case RAFBusinessAction.createRecord:
            ruleName = 'Create Record ...';
            break;
        case RAFBusinessAction.linkEmailToDeal:
            ruleName = 'Link Email to Deal ...';
            break;
        case RAFBusinessAction.createTicket:
            ruleName = 'Create Ticket ...';
            break;
        case RAFBusinessAction.alert:
            ruleName = 'Create Alert ...';
            break;
        default:
    }
    return ruleName;
}

export function setDisplayNameforRuleAction(action: string) {
    let ruleName = '';
    switch (action) {
        case RAFBusinessAction.showField:
            ruleName = 'Show field on the page';
            break;
        case RAFBusinessAction.hideField:
            ruleName = 'Hide field on the page';
            break;
        case RAFBusinessAction.setValue:
            ruleName = 'Set field value';
            break;
        case RAFBusinessAction.clearValue:
            ruleName = 'Clear field';
            break;
        case RAFBusinessAction.setCurrentDateAs:
            ruleName = 'Set Current date as';
            break;
        case RAFBusinessAction.setCurrentUserAs:
            ruleName = 'Set Current user as';
            break;
        case RAFBusinessAction.makeRequired:
            ruleName = 'Make field required';
            break;
        case RAFBusinessAction.showMessage:
            ruleName = 'Show message';
            break;
        case RAFBusinessAction.sendMail:
            ruleName = 'Send Mail';
            break;
        case RAFBusinessAction.sendSms:
            ruleName = 'Send SMS';
            break;
        case RAFBusinessAction.showStep:
            ruleName = 'Show Step';
            break;
        case RAFBusinessAction.hideStep:
            ruleName = 'Hide Step';
            break;
        default:
    }
    return ruleName;
}

export const getAllPageLayoutsByEntityId = (entityId?: string, returnNull?: boolean) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(entityId);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<PageLayoutJM>("EntityUID");
    relatedFilter.Rules.push(filter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    //listServiceRequest.ViewName = 'all_process_templates';
    return new Promise<PageLayoutJM[]>((resolve) => {
        if (isNotNullAndUndefined(entityId) || returnNull === true) {
            repositoryActions.postDataAndGetResponse('PageLayout/List', listServiceRequest, null, ContentType.applicationJson)
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }
                });
        } else {
            resolve(null);
        }
    });
};

export const getEntityByFormUID = (formUID: string) => {
    return new Promise<EntityRow>((resolve, reject) => {
        if (isNotNullAndUndefined(formUID)) {
            RetrieveFormLibraryById(formUID).then((formLibrary) => {
                if (isNotNullAndUndefined(formLibrary)) {
                    let entity = isNotNullAndUndefined(formLibrary) ? formLibrary.Entity : null;
                    if (isNotNullAndUndefined(entity)) {
                        getEntityByName(entity).then(entityRow => {
                            if (isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID)) {
                                resolve(entityRow);
                            } else {
                                resolve(null);
                            }
                        });
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const getAllPageLayoutsByFormUID = (formUID: string, returnNull?: boolean) => {
    return new Promise<PageLayoutJM[]>((resolve, reject) => {
        if (isNotNullAndUndefined(formUID) || returnNull === true) {
            RetrieveFormLibraryById(formUID).then((selectedForm) => {
                if (isNotNullAndUndefined(selectedForm)) {
                    let entity = isNotNullAndUndefined(selectedForm) ? selectedForm.Entity : null;
                    if (isNotNullAndUndefined(entity)) {
                        getEntityByName(entity).then(entityRow => {
                            if (isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID)) {
                                getAllPageLayoutsByEntityId(
                                    entityRow.UID
                                ).then((pageLayoutList) => {
                                    if (isNotNullAndUndefined(pageLayoutList)) {
                                        resolve(pageLayoutList);
                                    } else {
                                        resolve(null);
                                    }
                                });
                            } else {
                                resolve(null);
                            }
                        });
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            });

        } else {
            resolve(null);
        }
    });
};

export function RetrieveFormLibraryById(id: string) {
    return new Promise<FormLibraryRow>((resolve) => {
        if (isNotNullAndUndefined(id)) {
            return repositoryActions.postDataAndGetResponse(
                `FormLibrary/Retrieve`,
                { "EntityId": id },
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entity)) {
                    const responseDto: IFormLibraryDto = response.data.Entity as IFormLibraryDto;
                    let formLibrary: FormLibraryRow = ConvertFormLibraryDtoToFormLibrary(responseDto);
                    resolve(formLibrary);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const getFormLibraryByEntityName = (entityName?: string) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(entityName);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<FormLibraryRow>("Entity");
    relatedFilter.Rules.push(filter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    //listServiceRequest.ViewName = 'all_form_library';
    return new Promise<FormLibraryRow>((resolve) => {
        repositoryActions.postDataAndGetResponse('FormLibrary/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let formLibrary: FormLibraryRow[] = response.data.Entities;
                    resolve(formLibrary[0]);
                }
            });
    });
};

export const getFormLibraryByField = (fieldName?: string, fieldValue?: string) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(fieldValue);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = fieldName;
    relatedFilter.Rules.push(filter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    //listServiceRequest.ViewName = 'all_form_library';
    return new Promise<FormLibraryRow>((resolve) => {
        repositoryActions.postDataAndGetResponse('FormLibrary/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let formLibrary: FormLibraryRow[] = response.data.Entities;
                    resolve(formLibrary[0]);
                }
            });
    });
};

export const getModules = () => {
    return new Promise<ModuleRow[]>((resolve) => {
        const moduleRowsStorage = getSessionStorage('rafmodules');
        if (isNotNullAndUndefined(moduleRowsStorage) && !IsNullOrWhiteSpace(moduleRowsStorage)) {
            const allModules: ModuleRow[] = JSON.parse(moduleRowsStorage);
            const uniqModules: ModuleRow[] = R.uniq(allModules);
            resolve(uniqModules);
        }
        else {
            const moduleRequest: ModuleListRequest = new ModuleListRequest();
            return repositoryActions.postDataAndGetResponse(
                `Module/ListAll`,
                moduleRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)) {
                    const allModules: ModuleRow[] = response.data.Entities;
                    setSessionStorage('rafmodules', false, JSON.stringify(allModules));

                    const uniqModules: ModuleRow[] = R.uniq(allModules);
                    resolve(uniqModules);
                } else {
                    resolve(null);
                }
            });

        }
    });
};


export function GetPartners() {
    return new Promise<LookUpRow[]>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            `Tenant/Partners`,
            null,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data) &&
                isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
            } else {
                resolve(null);
            }
        });
    });
};


export function getTenantSourcePartners() {
    return new Promise<any[]>((resolve) => {
        const tenantSourcePartners = JSON.parse(getSessionStorage(StorageKey.TenantSourcePartners));
        if (isNotEmptyArray(tenantSourcePartners)) {
            resolve(tenantSourcePartners);
        } else {
            return repositoryActions
                .postDataAndGetResponse(
                    `Tenant/SourcePartners`,
                    null,
                    null,
                    ContentType.applicationJson,
                    false
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data) &&
                        isNotEmptyArray(response.data.Entities)
                    ) {
                        setSessionStorage(
                            StorageKey.TenantSourcePartners,
                            true,
                            JSON.stringify(response.data.Entities)
                        );
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => error);
        }
    });
}

export function AddGuestOrPartnerAPI(userEmail?: string, tenantUID?: string) {
    return new Promise<UserRow>((resolve, reject) => {
        if (isNotNullAndUndefined(userEmail) || isNotNullAndUndefined(tenantUID)) {
            let tenantUserRequest = new TenantUserRequest();
            tenantUserRequest.UserEmail = userEmail;
            let url = "User/AddGuest";
            if (isNotNullAndUndefined(tenantUID)) {
                url = "User/RetrievePartnerUser";
                tenantUserRequest.TenantUID = tenantUID;
            }
            repositoryActions
                .postDataAndGetResponse(
                    url,
                    tenantUserRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data) &&
                        isNotNullAndUndefined(response.data.Entity)
                    ) {
                        resolve(response.data.Entity);
                    }
                    else {
                        resolve(null);
                    }
                });

        } else {
            resolve(
                null
            );
        }
    });
}

export const SavePageLayout = (pageLayoutJM: PageLayoutJM) => {
    let id = isNotNullAndUndefined(pageLayoutJM) && isNotNullAndUndefined(pageLayoutJM.UID) ? pageLayoutJM.UID : null;
    let pageLayout = ConvertPageLayoutJMToPageLayoutJMDto(pageLayoutJM);
    return new Promise<PageLayoutJM>((resolve) => {
        repositoryActions
            .postDataAndGetResponse(
                `PageLayout/Save`,
                getSaveRequest(pageLayout, id),
                null,
                ContentType.applicationJson
            )
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.EntityId)
                ) {
                    removeSessionStorage(StorageKey.pageLayoutList_pageTypeentity + pageLayout.PageType + pageLayout.EntityUID, true);
                    let pageLayoutResponse: PageLayoutJM = pageLayoutJM;
                    pageLayoutResponse.UID = response.data.EntityId;
                    resolve(pageLayoutResponse);

                }
            });

    });
};

export function GetListByPage(pageUID?: string, entityUID?: string) {
    let pageRelatedListRequest = new PageRelatedListRequest();
    pageRelatedListRequest.EntityUID = isNotNullAndUndefined(entityUID) ? entityUID : null;
    pageRelatedListRequest.PageUID = isNotNullAndUndefined(pageUID) ? pageUID : null;
    let url = "PageRelatedList/ListByPage";

    return new Promise<RAFPageRelatedListRow[]>((resolve, reject) => {
        repositoryActions
            .postDataAndGetResponse(
                url,
                pageRelatedListRequest,
                null,
                ContentType.applicationJson
            )
            .then((response) => {

                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)
                ) {
                    let relatedPages: RAFPageRelatedListRow[] = response.data.Entities;
                    relatedPages.push({
                        DisplayOrder: 0,
                        Name: "Details",
                        UID: Guid.newGuid(),
                        IsCollapsed: false,
                        IsVisible: true,
                        RelatedEntity: "Details",
                    });
                    relatedPages &&
                        relatedPages.sort((x, y) =>
                            x.DisplayOrder > y.DisplayOrder ? 1 : -1
                        );
                    resolve(relatedPages);
                }
                else {
                    resolve(null);
                }
            });

    });
}

export function CheckFormExist(formName?: string, formCategory?: string) {
    return new Promise<boolean>((resolve /*, reject*/) => {
        getAllForms().then((allForms) => {
            let selectedFormLibraryRow: FormLibraryRow =
                allForms && allForms.find((x) => x.Title === formName && x.FormType === formCategory);
            if (isNotNullAndUndefined(selectedFormLibraryRow)) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};

export function CheckFormIsExist(formName?: string, formCategory?: string) {
    return new Promise<boolean>((resolve /*, reject*/) => {
        getAllForms().then((allForms) => {
            let selectedFormLibraryRow: FormLibraryRow =
                allForms && allForms.find((x) => x.Title === formName && x.FormType === formCategory);
            if (isNotNullAndUndefined(selectedFormLibraryRow)) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};


export function SaveEntity(entityRow?: EntityRow) {
    let id = isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID) ? entityRow.UID : null;
    let url = `Entity/Save`;
    return new Promise<string>((resolve /*, reject*/) => {
        return repositoryActions
            .postDataAndGetResponse(
                url,
                getSaveRequest(entityRow, id),
                null,
                ContentType.applicationJson,
                false
            )
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.EntityId)
                ) {
                    removeSessionStorage(StorageKey.simpleEntity_modulename + entityRow.EntityName, true);
                    resolve(response.data.EntityId);
                } else {
                    showWarningToast("Sorry something went wrong !");
                    return (null);
                }
            });

    });
};

export function flattenFormComponents(components: RAFDataModelRow[]) {
    let children: RAFDataModelRow[] = [];
    return components && components.map((item) => {
        const component = { ...item }; // use spread operator
        if (component.Components && component.Components.length) {
            children = [...children, ...item.Components];
        }
        //delete item.Components; // this will not affect the original array object
        return item;
    })
        .concat(
            children.length ? flattenFormComponents(children) : children
        );
};

export function flattenElements(elements) {
    let result = [];

    for (let element of elements) {
        if (element.elements) {
            result = result.concat(flattenElements(element.elements));
        } else {
            result.push(element);
        }
    }

    return result;
}

export function ConvertObjkeysToLowerCase(obj) {
    if (isNotNullAndUndefined(obj)) {


        if (obj instanceof Array) {
            for (var i in obj) {
                obj[i] = ConvertObjkeysToLowerCase(obj[i]);
            }
        }
        if (typeof (obj) === "string" || typeof (obj) === "number" || typeof (obj) === "boolean") {
            return obj;
        }
        const keys = Object.keys(obj);
        let n = keys.length;
        let lowKey;
        while (n--) {
            const key = keys[n];
            if (key === (lowKey = key.toLowerCase()))
                continue;
            obj[lowKey] = ConvertObjkeysToLowerCase(obj[key]);
            delete obj[key];
        }
    }
    return (obj);
}

export function updateFormFields(formComponents, formComponent) {
    if (formComponent.UID === formComponents.UID) {
        //formComponents = deepMerge(formComponents, formComponent);
        formComponents.Name = formComponent.Name;
        formComponents.DisplayName = formComponent.DisplayName;
        formComponents.Title = formComponent.Title;
        formComponents.DataType = formComponent.DataType;
        formComponents.ValueJson = formComponent.ValueJson;
        formComponents.Multiselect = formComponent.Multiselect;
        formComponents.Visible = formComponent.Visible;
        formComponents.IsSystem = formComponent.IsSystem;
        formComponents.IsMasterDetail = formComponent.IsMasterDetail;
        formComponents.EntityUID = formComponent.EntityUID;
        formComponents.IsDefault = formComponent.IsDefault;
        formComponents.InlineEdit = formComponent.InlineEdit;
        formComponents.Searchable = formComponent.Searchable;
        formComponents.ShowInView = formComponent.ShowInView;
        formComponents.FeedsEnabled = formComponent.FeedsEnabled;
        formComponents.IsSuperAdmin = formComponent.IsSuperAdmin;
        formComponents.ShowInFilter = formComponent.ShowInFilter;
        formComponents.Description = formComponent.Description;

        formComponents.AttributeSettings.DescriptionAsLabel =
            formComponent.AttributeSettings.DescriptionAsLabel;
        formComponents.AttributeSettings.IsRequired =
            formComponent.AttributeSettings.IsRequired;
        formComponents.AttributeSettings.IsPrimary =
            formComponent.AttributeSettings.IsPrimary;
        formComponents.AttributeSettings.IsReadonly =
            formComponent.AttributeSettings.IsReadonly;
        formComponents.AttributeSettings.DefaultValue =
            formComponent.AttributeSettings.DefaultValue;
        formComponents.AttributeSettings.EnableMassUpdate =
            formComponent.AttributeSettings.EnableMassUpdate;
        formComponents.AttributeSettings.IsIdentity =
            formComponent.AttributeSettings.IsIdentity;
        formComponents.AttributeSettings.Insertable =
            formComponent.AttributeSettings.Insertable;
        formComponents.AttributeSettings.Updatable =
            formComponent.AttributeSettings.Updatable;
        formComponents.AttributeSettings.UIType =
            formComponent.AttributeSettings.UIType;
        formComponents.AttributeSettings.IsMasterDetail =
            formComponent.AttributeSettings.IsMasterDetail;
        formComponents.AttributeSettings.DBDataType =
            formComponent.AttributeSettings.DBDataType;

        formComponents.InlineEdit = formComponent.InlineEdit;
        formComponents.Searchable = formComponent.Searchable;
        formComponents.ShowInView = formComponent.ShowInView;
        formComponents.FeedsEnabled = formComponent.FeedsEnabled;
        formComponents.ShowInFilter = formComponent.ShowInFilter;
        formComponents.Description = formComponent.Description;

        formComponents.DisplayOrder = formComponent.DisplayOrder;
        formComponents.CreatedDate = formComponent.CreatedDate;
        formComponents.ModifiedDate = formComponent.ModifiedDate;
        formComponents.CreatedBy = formComponent.CreatedBy;
        formComponents.ModifiedBy = formComponent.ModifiedBy;
        formComponents.RelatedEntities = formComponent.RelatedEntities;
        formComponents.AppId = formComponent.AppId;
        formComponents.IsQueryable = formComponent.IsQueryable;
        formComponents.CurrentNumber = formComponent.CurrentNumber;
        formComponents.Expression = formComponent.Expression;
        formComponents.FormulaDataType = formComponent.FormulaDataType;
        formComponents.CastDataType = formComponent.CastDataType;
        formComponents.FunctionName = formComponent.FunctionName;
        formComponents.ViewName = formComponent.ViewName;
        formComponents.Columns = formComponent.Columns;
        formComponents.UISettings = formComponent.UISettings;
    }
    // Apply formComponent to any child formComponentsects
    Object.values(formComponents).forEach((child) => {
        if (Array.isArray(child)) {
            child.forEach((el) => updateFormFields(el, formComponent));
        } else if (typeof child === "object" && child != null) {
            updateFormFields(child, formComponent);
        }
    });
}

export function updateFormComponents(formComponents, formComponent) {
    if (formComponent.UID === formComponents.UID) {
        //formComponents = deepMerge(formComponents, formComponent);
        //if added any settings added make sure to map the changed field here
        formComponents.Name = formComponent.Name;
        formComponents.DisplayName = formComponent.DisplayName;
        formComponents.Title = formComponent.Title;
        formComponents.ValueJson = formComponent.ValueJson;
        formComponents.AttributeSettings.DescriptionAsLabel =
            formComponent.AttributeSettings.DescriptionAsLabel;
        formComponents.AttributeSettings.IsRequired =
            formComponent.AttributeSettings.IsRequired;
        formComponents.AttributeSettings.IsReadonly =
            formComponent.AttributeSettings.IsReadonly;
        formComponents.IsDefault = formComponent.IsDefault;
        formComponents.Searchable = formComponent.Searchable;
        formComponents.ShowInFilter = formComponent.ShowInFilter;
        formComponents.ShowInView = formComponent.ShowInView;
        formComponents.Visible = formComponent.Visible;
        formComponents.Multiselect = formComponent.Multiselect;
        formComponents.ColumnCount = formComponent.ColumnCount;
        formComponents.IsCollapsible = formComponent.IsCollapsible;
        formComponents.ShowHeader = formComponent.ShowHeader;
        formComponents.Description = formComponent.Description;
        formComponents.Placeholder = formComponent.Placeholder;
        formComponents.IsCollapsed = formComponent.IsCollapsed;
        formComponents.ShowLabel = formComponent.ShowLabel;
        formComponents.AttributeSettings.DefaultValue =
            formComponent.AttributeSettings.DefaultValue;
        formComponents.AttributeSettings.EnableScore =
            formComponent.AttributeSettings.EnableScore;
        formComponents.AttributeSettings.Render =
            formComponent.AttributeSettings.Render;
        formComponents.FormRules = formComponent.FormRules;
        formComponents.RelatedSection = formComponent.RelatedSection;
        formComponents.Response = formComponent.Response;
        formComponents.ResponseOption = formComponent.ResponseOption;
        formComponents.ColumnWidth = formComponent.ColumnWidth;
        formComponents.HasStep = formComponent.HasStep;
        formComponents.StepId = formComponent.StepId;
        formComponents.Width = formComponent.Width;
        formComponents.MinWidth = formComponent.MinWidth;
        formComponents.MaxWidth = formComponent.MaxWidth;
        formComponents.TitleLocation = formComponent.TitleLocation;
        formComponents.DisplayInNewLine = formComponent.DisplayInNewLine;
    }
    // Apply formComponent to any child formComponentsects
    Object.values(formComponents).forEach((child) => {
        if (isNotNullAndUndefined(child) && Array.isArray(child)) {
            let newChildRows = [];
            child.forEach((item) => {//filter undefined
                if (isNotNullAndUndefined(item)) {
                    newChildRows.push(item);
                }
            });
            newChildRows.forEach((el) => updateFormComponents(el, formComponent));
        } else if (isNotNullAndUndefined(child) && typeof child === "object") {
            updateFormComponents(child, formComponent);
        }
    });
}

export function updateDataType(attributeList: AttributeRow[]) {
    let objAttributeList: AttributeRow[] = R.clone(attributeList);
    objAttributeList &&
        objAttributeList.forEach((item, index) => {
            if (isNotNullAndUndefined(item.DataType))
                if (
                    item.DataType === RAFDataType.RadioButton ||
                    item.DataType === RAFDataType.MultiSelect ||
                    item.DataType === RAFDataType.Question
                ) {
                    item.DataType = RAFDataType.Dropdown;
                }
            if (
                item.DataType === RAFDataType.LinkForm
            ) {
                item.DataType = RAFDataType.Lookup;
            }
        });
    return objAttributeList;
};


export function updateDisplayOrder(attributeList: AttributeRow[]) {
    let objAttributeList: AttributeRow[] = R.clone(attributeList);
    objAttributeList &&
        objAttributeList.forEach((item, index) => {
            if (isNotNullAndUndefined(item.AttributeSettings))
                item.DisplayOrder = index + 1;
        });
    return objAttributeList;
};

export function getMaxValue(objArray: any[], fieldName: string) {
    return Number(
        Math.max.apply(
            Math,
            objArray?.map(o => o[fieldName] || 0),
        ) || 0,
    );
}

export function EvaluateScore(values, queryAttributes: QueryAttributeJM[]) {
    let scoreEnabledFields: QueryAttributeJM[] = queryAttributes && queryAttributes.filter(
        (x) => x.EnableScore === true
    );
    let currentScore = 0;
    let maxScore = 0;
    if (
        isNotNullAndUndefined(scoreEnabledFields) &&
        scoreEnabledFields.length > 0
    ) {
        scoreEnabledFields.forEach((item) => {
            //update current field score,current score and max score value in object
            let currentField = queryAttributes && queryAttributes.find(
                (x) => x.PropertyName === item.PropertyName + "Score"
            );

            let currentFieldValueJson = isNotNullAndUndefined(item.ValueJson)
                ? item.ValueJson.find(
                    (x) =>
                        x.Name === values[item.PropertyName] ||
                        x.DisplayName === values[item.PropertyName]
                )
                : null;
            let currentFieldScore = isNotNullAndUndefined(currentFieldValueJson)
                ? currentFieldValueJson.Score
                : 0;
            let currentFieldMaxScore = getMaxValue(item.ValueJson, "Score");
            maxScore = maxScore + currentFieldMaxScore;
            currentScore = currentScore + currentFieldScore;
            values[currentField.PropertyName] = currentFieldScore;
            values["CurrentScore"] = currentScore;
            values["MaxScore"] = maxScore;
        });
    }
    return values;
};

export function EvaluateAndSetScore(values, queryAttributes: QueryAttributeJM[]) {
    let scoreEnabledFields: QueryAttributeJM[] = isNotEmptyArray(queryAttributes) ? queryAttributes.filter(
        (x) => x.EnableScore === true
    ) : [];

    if (
        isNotEmptyArray(scoreEnabledFields)
    ) {
        scoreEnabledFields.forEach((item) => {
            //update current field score in object
            let currentField = isNotEmptyArray(queryAttributes) ? queryAttributes.find(
                (x) => x.PropertyName === item.PropertyName
            ) : null;

            let currentFieldValueJson = isNotNullAndUndefined(item.ValueJson)
                ? item.ValueJson.find(
                    (x) =>
                        x.Name === values[item.PropertyName] ||
                        x.DisplayName === values[item.PropertyName]
                )
                : null;

            let currentFieldScore = isNotNullAndUndefined(currentFieldValueJson)
                ? currentFieldValueJson.Score
                : 0;

            values[currentField.PropertyName + 'Score'] = currentFieldScore;
        });
    }
    return values;
};

export function getUpdatedFormDataModel(formModel: any, filesToUpload: any, moduleName: string, questionsToUpload: any, mode: 'create' | 'edit') {
    let formData = formModel.data;
    return new Promise<any>((resolve, reject) => {
        if (isNotEmptyArray(filesToUpload)) {
            Promise.all(
                filesToUpload.map(async (file) => {
                    let filestoUpload = await fileUpload(file, moduleName);
                    if (isNotNullAndUndefined(filestoUpload)) {
                        let attachment: LookUpRow = {
                            UID: filestoUpload.entityId,
                            Value: filestoUpload.objectName,
                        };
                        return attachment;
                    } else {
                        let attachment: LookUpRow = {
                            UID: null,
                            Value: null,
                        };
                        return attachment;
                    }
                })
            ).then((responseAttachments) => {
                const attachments = isNotEmptyArray(responseAttachments) ? responseAttachments.filter(x => isNotNullAndUndefined(x.UID)) : [];
                for (let i = 0; i < questionsToUpload.length; i++) {
                    const questionName = questionsToUpload[i];
                    let existingAttachments = formData[questionName];
                    let attachmentsArray = attachments as LookUpRow[];
                    const updatedAttachments =
                        existingAttachments ?
                            existingAttachments.filter(
                                (existingAttachment) =>
                                    !attachmentsArray.some(
                                        (attachment) =>
                                            attachment.Value === existingAttachment.name
                                    )
                            ) : null;
                    if (isNotEmptyArray(updatedAttachments)) {
                        attachmentsArray = [...attachmentsArray, ...updatedAttachments];
                    }
                    // formModel.setValue(questionName, attachmentsArray);
                    formData[questionName] = attachmentsArray;
                }

                if (mode === 'edit') {
                    formModel.getAllQuestions().forEach(function (question) {
                        if (formData[question.name] === undefined) {
                            formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                        }
                    });
                }

                resolve(formData);

            });
        }
        else {
            formModel.getAllQuestions().forEach(function (question) {
                if (formData[question.name] === undefined) {
                    formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                }
            });

            resolve(formData);
        }

    });
}

export const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 150, 150, 'JPEG', 100, 0,
        uri => {
            resolve(uri);
        },
        'base64'
    );
});

export function uploadFileAndGetFormData(formModel?: any, moduleName?: string, mode?: 'create' | 'edit', careShiftLogRow?: any) {
    let formData = formModel.data;
    const attachmentQuestions = formModel
        .getAllQuestions()
        .filter((question) => question.getType() === "file");
    const attachmentQuestionNames =
        attachmentQuestions &&
        attachmentQuestions.map((question) => question.name);
    let attachmentsToUpload = [];
    for (let i = 0; i < attachmentQuestionNames.length; i++) {
        const questionName = attachmentQuestionNames[i];
        attachmentsToUpload = formData[questionName];
    }




    return new Promise<any>((resolve, reject) => {
        if (isNotEmptyArray(attachmentsToUpload)) {

            Promise.all(
                attachmentsToUpload.map(async (file) => {
                    let fileContent = base64ToFile(file.content, file.name);
                    let filestoUpload = await fileUpload(fileContent, file.UID, moduleName);
                    if (isNotNullAndUndefined(filestoUpload)) {
                        let attachment: LookUpRow = {
                            UID: filestoUpload.entityId,
                            Value: filestoUpload.objectName,
                        };
                        return attachment;
                    } else {
                        let attachment: LookUpRow = {
                            UID: null,
                            Value: null,
                        };
                        return attachment;
                    }
                })
            ).then((responseAttachments) => {
                const attachments = isNotEmptyArray(responseAttachments) ? responseAttachments.filter(x => isNotNullAndUndefined(x.UID)) : [];
                for (let i = 0; i < attachmentQuestionNames.length; i++) {
                    const questionName = attachmentQuestionNames[i];
                    let existingAttachments = formData[questionName];
                    let attachmentsArray = attachments as LookUpRow[];
                    const updatedAttachments =
                        existingAttachments ?
                            existingAttachments.filter(
                                (existingAttachment) =>
                                    !attachmentsArray.some(
                                        (attachment) =>
                                            attachment.Value === existingAttachment.name
                                    )
                            ) : null;
                    if (isNotEmptyArray(updatedAttachments)) {
                        attachmentsArray = [...attachmentsArray, ...updatedAttachments];
                    }
                    // formModel.setValue(questionName, attachmentsArray);
                    formData[questionName] = attachmentsArray;
                }

                if (mode === 'edit') {
                    formModel.getAllQuestions().forEach(function (question) {
                        if (formData[question.name] === undefined) {
                            formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                        }
                    });
                }
                resolve(formData);

            });
        }
        else {
            formModel.getAllQuestions().forEach(function (question) {
                if (formData[question.name] === undefined) {
                    formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                }
            });

            resolve(formData);
        }

    });
}

export function getPredefinedURLAndUplodToS3(formModel?: any, moduleName?: string, mode?: 'create' | 'edit', existingFormData?: any) {
    let formModelData = formModel.data;
    return new Promise<any>(async (resolve, reject) => {
        let s3UploadResponse = await uploadFileAndGetPredefinedURL(formModel, moduleName, existingFormData);
        let saveListRequest = [];
        const attachmentQuestions = formModel
            .getAllQuestions()
            .filter((question) => question.getType() === "file");
        const attachmentQuestionNames =
            attachmentQuestions &&
            attachmentQuestions.map((question) => question.name);

        if (isNotEmptyArray(s3UploadResponse)) {
            let attachments: LookUpRow[] = [];

            s3UploadResponse.forEach((item) => {
                let attachment = { UID: item.UID, Value: item.DisplayName };
                attachments.push(attachment);
            });

            for (let i = 0; i < attachmentQuestionNames.length; i++) {
                const questionName = attachmentQuestionNames[i];
                let existingAttachments = formModelData[questionName];
                let attachmentsArray = isNotEmptyArray(attachments) ? attachments : [];
                const updatedAttachments =
                    isNotEmptyArray(existingAttachments) ?
                        existingAttachments.filter(
                            (existingAttachment) =>
                                !attachmentsArray.some(
                                    (attachment) =>
                                        attachment.Value === existingAttachment.name
                                )
                        ) : null;
                if (isNotEmptyArray(updatedAttachments)) {
                    attachmentsArray = [...attachmentsArray, ...updatedAttachments];
                }
                // formModel.setValue(questionName, attachmentsArray);
                formModelData[questionName] = attachmentsArray;
            }
            s3UploadResponse.forEach((item) => {
                item["RelatedToType"] = moduleName;
                item["Entity"] = moduleName;
                let entity = { Entity: item };
                saveListRequest.push(entity);
            });

            repositoryActions
                .postDataAndGetResponse(
                    "ContentLibrary/SaveList",
                    saveListRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (mode === 'edit') {
                        formModel.getAllQuestions().forEach(function (question) {
                            if (formModelData[question.name] === undefined) {
                                formModelData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                            }
                        });
                    }
                    resolve(formModelData);

                });
        }
        else {
            formModel.getAllQuestions().forEach(function (question) {
                if (formModelData[question.name] === undefined) {
                    formModelData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                }
            });

            resolve(formModelData);
        }
    });
}

export function uploadFileAndGetPredefinedURL(formModel, moduleName, existingFormData) {
    let formData = formModel.data;
    const attachmentQuestions = formModel
        .getAllQuestions()
        .filter((question) => question.getType() === "file");
    const attachmentQuestionNames =
        attachmentQuestions &&
        attachmentQuestions.map((question) => question.name);
    let attachmentsToUpload = [];
    for (let i = 0; i < attachmentQuestionNames.length; i++) {
        const questionName = attachmentQuestionNames[i];
        let newAttachments = formData[questionName];
        if (isNotEmptyArray(newAttachments)) {
            if (isNotNullAndUndefined(existingFormData)) {
                let existingAttachments = existingFormData[questionName];
                if (isNotEmptyArray(existingAttachments)) {
                    newAttachments = newAttachments.filter(
                        (newAttachment) => !existingAttachments.some(existing => existing.UID === newAttachment.UID)
                    );
                }
            }

            attachmentsToUpload = [...attachmentsToUpload, ...newAttachments];
        }
    }

    let retVal = [];
    return new Promise<any>((resolve, reject) => {
        if (isNotEmptyArray(attachmentsToUpload)) {

            let url = "ContentLibrary/PreSignedUrl";
            let documentPreSignedUrlRequest = new DocumentPreSignedUrlRequest();
            documentPreSignedUrlRequest.Thumnail = true;
            documentPreSignedUrlRequest.Documents = [];
            Array.from(attachmentsToUpload).map(file => {
                documentPreSignedUrlRequest.Documents.push({
                    UID: file.UID,
                    FileName: file.name,
                    MimeType: file.type,
                    Entity: moduleName
                });
            });

            repositoryActions
                .postDataAndGetResponse(
                    url,
                    documentPreSignedUrlRequest,
                    null,
                    ContentType.applicationJson
                )
                .then(async (response) => {

                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data)
                    ) {
                        let fileUrls: RAFFilePreSignedURL[] = response.data;

                        if (isNotEmptyArray(fileUrls)) {


                            for (let i = 0; i < fileUrls.length; i++) {
                                const file = attachmentsToUpload && attachmentsToUpload.find(x => x.UID === fileUrls[i].EntityId);
                                let fileToUpload;
                                if (fileUrls[i].MimeType.startsWith('image/')) {
                                    let fileContent = base64ToFile(file.content, file.name);
                                    if (fileUrls[i].DocumentType === 'Thumbnail') {
                                        //resize image if mimetype is File and upload
                                        let resizedImage = await getResizedImage(fileContent);
                                        fileToUpload = resizedImage;
                                    } else {
                                        fileToUpload = fileContent;
                                    }
                                } else {
                                    if (fileUrls[i].DocumentType === 'File') {

                                        // Convert the base64 string to a Blob
                                        const response = await fetch(file.content);
                                        const blob = await response.blob();

                                        // Create a File from the Blob
                                        const newFile = new File([blob], fileUrls[i].ObjectName, { type: fileUrls[i].MimeType, lastModified: Date.now() });
                                        fileToUpload = newFile;
                                    }
                                }

                                const uploadUrl = fileUrls[i].UploadURL;
                                let filesToSave;

                                if (isNotNullAndUndefined(uploadUrl) && isNotNullAndUndefined(fileToUpload)) {
                                    try {
                                        await uploadFileWithRetry(fileToUpload, uploadUrl);
                                        console.log(`File ${file.name} uploaded successfully`);
                                        if (fileUrls[i].DocumentType === 'File') {
                                            filesToSave = {
                                                UID: fileUrls[i].EntityId,
                                                DisplayName: fileUrls[i].ObjectName,
                                                FileName: fileUrls[i].ObjectName,
                                                MimeType: fileUrls[i].MimeType,
                                                FileSize: fileToUpload.size,
                                                DocumentType: fileUrls[i].DocumentType,
                                                CurrentStatus: ContentLibraryCurrentStatus.Published
                                            };
                                            retVal.push({ ...filesToSave });
                                        }
                                    } catch (error) {
                                        console.error(`Failed to upload ${file.name}:`, error);
                                    }
                                }

                            }

                            resolve(retVal);
                        }
                        else {
                            resolve(null);
                        }
                    }
                    else {
                        resolve(null);
                    }
                });
        }
        else {
            resolve(null);
        }



    });
}

export function downloadAndGetPredifinedURL(recordIds?: string[]) {
    let url = "ContentLibrary/PreSignedDownloadUrl";
    let documentPreSignedUrlRequest = new DocumentPreSignedUrlRequest();
    documentPreSignedUrlRequest.Thumnail = true;
    documentPreSignedUrlRequest.Documents = [];
    Array.from(recordIds).map(recordId => {
        documentPreSignedUrlRequest.Documents.push({
            UID: recordId,
        });
    });

    return new Promise<RAFFilePreSignedURL[]>((resolve, reject) => {
        repositoryActions
            .postDataAndGetResponse(
                url,
                documentPreSignedUrlRequest,
                null,
                ContentType.applicationJson
            )
            .then(async (response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotEmptyArray(response.data)
                ) {
                    let downloadUrls: RAFFilePreSignedURL[] = response.data;
                    resolve(downloadUrls);
                }
                else {
                    resolve(null);
                }
            });
    });
}

const MAX_RETRIES = 3;
const RETRY_INTERVAL = 2000; // 2 seconds

async function uploadFileWithRetry(file, url, retries = MAX_RETRIES) {
    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
            },

        });

        //console.log('response',response)
        if (!response.ok) {
            throw new Error('Upload failed');
        }

        return response;
    } catch (error) {
        console.log('error', error);
        if (retries > 0) {
            console.warn(`Retrying upload for ${file.name}, attempts left: ${retries}`);
            await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL));
            return uploadFileWithRetry(file, url, retries - 1);
        } else {
            throw new Error(`Failed to upload ${file.name} after ${MAX_RETRIES} attempts`);
        }
    }
}