import * as React from "react";
import { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { getElement } from "../../../RAFModules/Common/RAFFieldHelper";
import { RAFDataModelRow } from "../RAFPage/PageLayoutJM";

interface IProps {
  formDataFieldName?: string;
  formDataModelFieldName?: string;
  validateFields?: boolean;
  allowSubmit?: boolean;
  autoSave?: boolean;
  isDisabled?: boolean;
  rafComponents: RAFDataModelRow;
  showLabel?: boolean;
}

function RAFIOAssesmentModelRender({
  rafComponents,
  showLabel,
  ...props
}: PropsWithChildren<IProps>) {
  // const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  let components: RAFDataModelRow = rafComponents; // getFormValue(rafFormContextValue, props.formDataModelFieldName);
  // if (
  //   isNotNullAndUndefined(rafFormContextValue) &&
  //   isNotNullAndUndefined(rafFormContextValue.values)
  // ) {
  //   components = rafFormContextValue.values;
  // }

  const renderformComponent = (components?: RAFDataModelRow) => {
    let retVal = <div></div>;
    const showComponet = components.Visible;
    const showComponentLabel = isNotNullAndUndefined(showLabel)
      ? showLabel
      : components.ShowLabel;
    const isDisabled =
      props.isDisabled === true
        ? true
        : components.AttributeSettings.IsReadonly === true
          ? true
          : false;
    if (showComponet) {
      retVal = getElement(
        null,
        null,
        components.DataType,
        components.Name,
        components.DisplayName,
        components.Title,
        props.validateFields,
        components.AttributeSettings.IsRequired,
        showComponentLabel,
        isDisabled,
        components.AttributeSettings.UIType,
        false,
        components.ValueJson,
        null,
        components.AttributeSettings.DefaultValue,
        null,
        null, //"Edit" | "Details" richtextEditor
        components.Placeholder,
        components.DisplayName,
        props.formDataFieldName
      );
    }

    return retVal;
  };

  if (isNotNullAndUndefined(components)) {
    return <div className="col-12">{renderformComponent(components)}</div>;
  } else {
    return;
  }
}
export default RAFIOAssesmentModelRender;
