import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  SelectEventArgs,
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import React, { Fragment, PropsWithChildren, Reducer, useReducer } from "react";
import { tabAnimationSettings } from "../../../RAFComponents/helpers/AppHelper";
import { isNotEmptyArray, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import {
  FormLibraryRow,
  RAFFormStyle,
} from "../../../RAFComponents/models/Common/FormLibraryRow";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import { RAFEntityContext } from "../../../RAFModules/Common/Providers/RAFEntityProvider";
import { flattenFormComponents } from "../../helpers/RMutils";
import { RAFDataModelRow } from "../RAFPage/PageLayoutJM";
import "./../../../styles/cardSectionStyle.scss";
import "./FormLibraryStyle.scss";
import RAFFormComponentModelRender from "./RAFFormComponentModelRender";
import RAFFormInputModelRender from "./RAFFormInputModelRender";

interface IProps {
  formDataFieldName?: string;
  formDataModelFieldName?: string;
  components?: RAFDataModelRow[];
  validateFields?: boolean;
  isPreviewMode?: boolean;
  allowSubmit?: boolean;
  autoSave?: boolean;
  isDisabled?: boolean;
  colClass?: string;
  moduleName?: string;
  viewAttributes?: QueryAttributeJM[];
  previewMode?: "Mobile" | "Desktop";
}

interface IState {
  selectedTabIndex?: number;
}

function RAFFormModelRenderer({
  components,
  moduleName,
  viewAttributes,
  formDataFieldName,
  ...props
}: PropsWithChildren<IProps>) {
  const entityContext = React.useContext(RAFEntityContext);
  const formLibrary: FormLibraryRow = entityContext.formLibrary;
  //formLibrary.PageType = RAFFormStyle.Wizard;
  let formPageComponent: RAFDataModelRow[] = [];
  let flattenFormComponent: RAFDataModelRow[] =
    flattenFormComponents(components);

  let tabObj: TabComponent;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedTabIndex: 0,
    }
  );

  formPageComponent =
    flattenFormComponent &&
    flattenFormComponent.filter(
      (x) => x.DataType === RAFDataType.Page && x.Visible === true
    );

  const renderFormComponentModelContent = (item: RAFDataModelRow, isSelectedTab: boolean) => {
    if (isNotNullAndUndefined(item) && isNotEmptyArray(item.Components)) {
      const formComponentModel: RAFDataModelRow[] = item.Components;
      return (
        <div className={`row g-2 g-md-3 ${isSelectedTab ? '' : 'hidden'}`}>
          {formComponentModel.map((item) => {
            const formItemWidth = item["Width"];
            const formItemMinWidth = item["MinWidth"];
            const formItemMaxWidth = item["MaxWidth"];
            //const formItemTitleLocation = item["TitleLocation"];
            const formItemDisplayInNewLine = item["DisplayInNewLine"];
            return (
              <div
                className={
                  isNotNullAndUndefined(item.AttributeSettings.Render) &&
                    item.AttributeSettings.Render === false
                    ? props.colClass + " hidden"
                    : props.colClass
                }
                id={"formItemDiv_" + item.UID}
                key={"formItem_" + item.UID}
                style={{
                  width:
                    isNotNullAndUndefined(formItemWidth) &&
                      !formItemDisplayInNewLine
                      ? formItemWidth
                      : "100%",
                  ...(isNotNullAndUndefined(formItemMinWidth) &&
                    !formItemDisplayInNewLine
                    ? { minWidth: formItemMinWidth }
                    : {}),
                  ...(isNotNullAndUndefined(formItemMaxWidth) &&
                    !formItemDisplayInNewLine
                    ? { maxWidth: formItemMaxWidth }
                    : {}),
                  // ...(isNotNullAndUndefined(formItemDisplayInNewLine)
                  //   ? { flex: "1 0" }
                  //   : {}),
                }}
              >
                <div
                  style={{
                    width:
                      isNotNullAndUndefined(formItemWidth) &&
                        formItemDisplayInNewLine
                        ? formItemWidth
                        : "100%",
                    ...(isNotNullAndUndefined(formItemMinWidth) &&
                      formItemDisplayInNewLine
                      ? { minWidth: formItemMinWidth }
                      : {}),
                    ...(isNotNullAndUndefined(formItemMaxWidth) &&
                      formItemDisplayInNewLine
                      ? { maxWidth: formItemMaxWidth }
                      : {}),
                  }}
                >
                  {item.DataType === RAFDataType.Panel ? (
                    <div className={"e-card bp-card bp-page p-0 cardSection"}>
                      <div className="e-card-content p-0">
                        <div className="formLibrary__content">
                          <RAFCollapse
                            title={item.DisplayName}
                            removeContentCardPadding
                            // fullRowClick={true}
                            fullRowClick
                            showSectionHeader={
                              isNotNullAndUndefined(item.ShowHeader)
                                ? item.ShowHeader
                                : false
                            }
                            headerRowClassName="cardSection__header bg-light-blue-3"
                            contentCardClassName={
                              "cardSection__content p-2 p-md-3"
                            }
                            IsCollapsed={item.IsCollapsed}
                          >
                            <div className="row g-3 justify-content-end">
                              <RAFFormComponentModelRender
                                formDataFieldName={formDataFieldName}
                                components={item.Components}
                                validateFields={
                                  isNotNullAndUndefined(props.validateFields)
                                    ? props.validateFields
                                    : true
                                }
                                isPreviewMode={props.isPreviewMode}
                                isDisabled={props.isDisabled}
                                allowSubmit={false}
                                colClass={
                                  item.ColumnCount
                                    ? props.previewMode === "Mobile"
                                      ? `col-12`
                                      : `col-md-${12 / item.ColumnCount}`
                                    : props.previewMode === "Mobile"
                                      ? `col-12`
                                      : `col-md-6`
                                }
                                // colClass={`col-md-12`}
                                moduleName={moduleName}
                                viewAttributes={viewAttributes}
                              />
                            </div>
                          </RAFCollapse>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <RAFFormInputModelRender
                      formDataFieldName={formDataFieldName}
                      rafComponent={item}
                      validateFields={
                        isNotNullAndUndefined(props.validateFields)
                          ? props.validateFields
                          : true
                      }
                      isPreviewMode={props.isPreviewMode}
                      isDisabled={props.isDisabled}
                      allowSubmit={props.allowSubmit}
                      moduleName={moduleName}
                      viewAttributes={viewAttributes}
                      showLabel
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderTabContent = () => {
    const { selectedTabIndex } = state;
    if (isNotEmptyArray(formPageComponent)) {
      return (
        formPageComponent.map((item, index) => {
          return (
            <Fragment key={index}>
              {renderFormComponentModelContent(item, selectedTabIndex === index ? true : false)}
            </Fragment>
          );
        })
      );
    }
  };

  const renderWizardTabContent = () => {
    let selectedTabIndex = tabObj && tabObj.selectedItem;
    let formComponentModel: RAFDataModelRow[] = [];
    if (
      isNotNullAndUndefined(formPageComponent) &&
      isNotNullAndUndefined(formPageComponent[selectedTabIndex])
    ) {
      formComponentModel = formPageComponent[selectedTabIndex].Components;
    }
    if (isNotNullAndUndefined(formComponentModel)) {
      let totalItems: number = formPageComponent && formPageComponent.length;
      return (
        <div className="section__container">
          <div className="section__div section__divY page_container my-3">
            <div className="row g-2 g-md-3">
              {formComponentModel.map((item) => {
                let formItemWidth = item["Width"];
                let formItemMinWidth = item["MinWidth"];
                let formItemMaxWidth = item["MaxWidth"];
                let formItemTitleLocation = item["TitleLocation"];
                let formItemDisplayInNewLine = item["DisplayInNewLine"];
                return (
                  <div
                    className={
                      isNotNullAndUndefined(item.AttributeSettings.Render) &&
                        item.AttributeSettings.Render === false
                        ? props.colClass + " hidden"
                        : props.colClass
                    }
                    id={"formItemDiv_" + item.UID}
                    key={"formItem_" + item.UID}
                    style={{
                      width:
                        isNotNullAndUndefined(formItemWidth) &&
                          !formItemDisplayInNewLine
                          ? formItemWidth
                          : "100%",
                      ...(isNotNullAndUndefined(formItemMinWidth) &&
                        !formItemDisplayInNewLine
                        ? { minWidth: formItemMinWidth }
                        : {}),
                      ...(isNotNullAndUndefined(formItemMaxWidth) &&
                        !formItemDisplayInNewLine
                        ? { maxWidth: formItemMaxWidth }
                        : {}),
                      // ...(isNotNullAndUndefined(formItemDisplayInNewLine)
                      //   ? { flex: "1 0" }
                      //   : {}),
                    }}
                  >
                    <div
                      style={{
                        width:
                          isNotNullAndUndefined(formItemWidth) &&
                            formItemDisplayInNewLine
                            ? formItemWidth
                            : "100%",
                        ...(isNotNullAndUndefined(formItemMinWidth) &&
                          formItemDisplayInNewLine
                          ? { minWidth: formItemMinWidth }
                          : {}),
                        ...(isNotNullAndUndefined(formItemMaxWidth) &&
                          formItemDisplayInNewLine
                          ? { maxWidth: formItemMaxWidth }
                          : {}),
                      }}
                    >
                      {item.DataType === RAFDataType.Panel ? (
                        <div
                          className={"e-card bp-card bp-page p-0 cardSection"}
                        >
                          <div className="e-card-content p-0">
                            <div className="formLibrary__content">
                              <RAFCollapse
                                title={item.DisplayName}
                                removeContentCardPadding
                                // fullRowClick={true}
                                fullRowClick
                                showSectionHeader={
                                  isNotNullAndUndefined(item.ShowHeader)
                                    ? item.ShowHeader
                                    : false
                                }
                                headerRowClassName="cardSection__header bg-light-blue-3"
                                contentCardClassName={
                                  "cardSection__content p-2 p-md-3"
                                }
                                IsCollapsed={item.IsCollapsed}
                              >
                                <div className="row g-3 justify-content-end">
                                  <RAFFormComponentModelRender
                                    formDataFieldName={formDataFieldName}
                                    components={item.Components}
                                    validateFields={
                                      isNotNullAndUndefined(
                                        props.validateFields
                                      )
                                        ? props.validateFields
                                        : true
                                    }
                                    isPreviewMode={props.isPreviewMode}
                                    isDisabled={props.isDisabled}
                                    allowSubmit={false}
                                    colClass={
                                      item.ColumnCount
                                        ? props.previewMode === "Mobile"
                                          ? `col-12`
                                          : `col-md-${12 / item.ColumnCount}`
                                        : props.previewMode === "Mobile"
                                          ? `col-12`
                                          : `col-md-6`
                                    }
                                    // colClass={`col-md-12`}
                                    moduleName={moduleName}
                                    viewAttributes={viewAttributes}
                                    previewMode={props.previewMode}
                                  />
                                </div>
                              </RAFCollapse>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <RAFFormInputModelRender
                          formDataFieldName={formDataFieldName}
                          rafComponent={item}
                          validateFields={
                            isNotNullAndUndefined(props.validateFields)
                              ? props.validateFields
                              : true
                          }
                          isPreviewMode={props.isPreviewMode}
                          isDisabled={props.isDisabled}
                          allowSubmit={props.allowSubmit}
                          moduleName={moduleName}
                          viewAttributes={viewAttributes}
                          showLabel
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {totalItems > 1 && (
            <div className="p-3 py-2 border-top bg-white">
              <div className="row g-1">
                <div className="col-auto ms-auto">
                  <ButtonComponent
                    type="button"
                    id="wizardPreviousBtn"
                    cssClass="e-outline"
                    isPrimary
                    iconCss="fas fa-chevrons-left"
                    disabled={selectedTabIndex === 0 ? true : false}
                    onClick={() => wizardBtnClicked("wizardPreviousBtn")}
                  >
                    Previous
                  </ButtonComponent>
                </div>
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    id="wizardNextBtn"
                    cssClass="e-outline"
                    isPrimary
                    disabled={
                      selectedTabIndex === totalItems - 1 ? true : false
                    }
                    iconCss="fas fa-chevrons-right"
                    onClick={() => wizardBtnClicked("wizardNextBtn")}
                    iconPosition="right"
                  >
                    Next
                  </ButtonComponent>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  function removeItem(): void {
    let tabItems: any = tabObj && tabObj.element.querySelectorAll(".e-item");
    tabItems &&
      tabItems.forEach((item: Element, index: number) => {
        if (index > 0) {
          item.remove();
        }
      });
  }

  function wizardBtnClicked(selectedBtn: string): void {
    let selectedItem = tabObj && tabObj.selectedItem;
    switch (selectedBtn) {
      case "wizardNextBtn":
        removeItem();
        tabObj && tabObj.enableTab(selectedItem + 1, true);
        tabObj && tabObj.select(selectedItem + 1);
        tabObj && tabObj.enableTab(selectedItem, false);
        break;
      case "wizardPreviousBtn":
        tabObj && tabObj.enableTab(selectedItem - 1, true);
        tabObj && tabObj.select(selectedItem - 1);
        tabObj && tabObj.enableTab(selectedItem, false);
        break;
      default:
        break;
    }
  }

  function tabSelecting(e: SelectEventArgs): void {
    if (e.isSwiped) {
      e.cancel = true;
    }
  }

  function tabCreated(): void {
    if (
      isNotNullAndUndefined(formPageComponent) &&
      formPageComponent.length > 0
    ) {
      formPageComponent.forEach((item, index) => {
        if (index === 0) {
          tabObj && tabObj.enableTab(index, true);
          tabObj && tabObj.select(index);
        } else {
          tabObj && tabObj.enableTab(index, false);
        }
      });
    }
  }

  const tabSelected = (selectedTabIndex?: number) => {
    setState({
      selectedTabIndex,
    });
  };

  if (
    formLibrary &&
    (formLibrary.PageType === RAFFormStyle.Page ||
      formLibrary.PageType === RAFFormStyle.Dialog)
  ) {
    if (
      isNotNullAndUndefined(formPageComponent) &&
      formPageComponent.length > 0
    ) {
      return (
        <div className="col-12">
          <div
            className={
              isNotNullAndUndefined(props.isPreviewMode) && !props.isPreviewMode
                ? "p-3"
                : "formPageContainer page_container"
            }
          >
            <div className="row g-0 gy-3">
              {formPageComponent.map((item) => {
                let formItemWidth = item["Width"];
                let formItemMinWidth = item["MinWidth"];
                let formItemMaxWidth = item["MaxWidth"];
                let formItemTitleLocation = item["TitleLocation"];
                let formItemDisplayInNewLine = item["DisplayInNewLine"];
                return (
                  <div
                    className={
                      isNotNullAndUndefined(props.colClass)
                        ? props.colClass
                        : "col-12"
                    }
                    id={"formItemDiv_" + item.UID}
                    key={"formItem_" + item.UID}
                    style={{
                      width: isNotNullAndUndefined(formItemWidth)
                        ? formItemWidth
                        : "100%",
                      ...(isNotNullAndUndefined(formItemMinWidth)
                        ? { minWidth: formItemMinWidth }
                        : {}),
                      ...(isNotNullAndUndefined(formItemMaxWidth)
                        ? { maxWidth: formItemMaxWidth }
                        : {}),
                      // ...(isNotNullAndUndefined(formItemDisplayInNewLine)
                      //   ? { flex: "1 0" }
                      //   : {}),
                    }}
                  >
                    <div className="cardPanel formLibrary__content">
                      {isNotNullAndUndefined(item.ShowHeader) &&
                        item.ShowHeader === true && (
                          <div className="cardPanel__header">
                            <span className="m-0">{item.DisplayName}</span>
                          </div>
                        )}
                      <div className="row gx-3 gy-4 justify-content-end">
                        <RAFFormComponentModelRender
                          formDataFieldName={formDataFieldName}
                          components={item.Components}
                          validateFields={
                            isNotNullAndUndefined(props.validateFields)
                              ? props.validateFields
                              : true
                          }
                          isPreviewMode={props.isPreviewMode}
                          isDisabled={props.isDisabled}
                          allowSubmit={false}
                          moduleName={moduleName}
                          viewAttributes={viewAttributes}
                          colClass="col-12"
                          previewMode={props.previewMode}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <RAFFormComponentModelRender
          formDataFieldName={formDataFieldName}
          components={components}
          validateFields={
            isNotNullAndUndefined(props.validateFields)
              ? props.validateFields
              : true
          }
          isPreviewMode={props.isPreviewMode}
          isDisabled={props.isDisabled}
          allowSubmit={false}
          moduleName={moduleName}
          viewAttributes={viewAttributes}
          colClass="col-12"
          previewMode={props.previewMode}
        />
      );
    }
  } else if (formLibrary && formLibrary.PageType === RAFFormStyle.Tab) {
    if (isNotNullAndUndefined(formPageComponent)) {
      // return (
      //   <TabComponent
      //     cssClass="e-fill custom-tab-footer-border bp-tab active-bg-gray formLibraryTab"
      //     animation={tabAnimationSettings}
      //     ref={(tab) => (tabObj = tab)}
      //     height="100%"
      //     loadOn="Init"
      //   >
      //     <TabItemsDirective>
      //       {formPageComponent.map((pageComponent) => {
      //         return (
      //           <TabItemDirective
      //             key={pageComponent.UID}
      //             header={{ text: pageComponent.DisplayName }}
      //             content={renderTabContent}
      //           />
      //         );
      //       })}
      //     </TabItemsDirective>
      //   </TabComponent>
      // );
      return (
        <div className="section__container">
          <TabComponent
            cssClass="e-fill custom-tab-footer-border bp-tab active-bg-gray active-bg-white formLibraryTab raf-tab-lg"
            loadOn="Demand"
            selectedItem={state.selectedTabIndex}
            animation={tabAnimationSettings}
            selected={(e) => {
              tabSelected(e.selectedIndex);
            }}
          >
            <div className="d-flex align-items-center justify-content-between bg-blue w-100">
              <div className="e-tab-header">
                {formPageComponent.map((pageComponent) => {
                  return (
                    <div key={pageComponent.UID}>
                      {pageComponent.DisplayName}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="e-content"></div>
          </TabComponent>
          <div className="px-2 my-2 px-md-3 my-md-3 section__div page_container customScrollBar">
            {renderTabContent()}
          </div>
        </div>
      );
    } else {
      return (
        <RAFFormComponentModelRender
          formDataFieldName={formDataFieldName}
          components={components}
          validateFields={
            isNotNullAndUndefined(props.validateFields)
              ? props.validateFields
              : true
          }
          isPreviewMode={props.isPreviewMode}
          isDisabled={props.isDisabled}
          allowSubmit={false}
          moduleName={moduleName}
          viewAttributes={viewAttributes}
          colClass="col-12"
          previewMode={props.previewMode}
        />
      );
    }
  } else if (formLibrary && formLibrary.PageType === RAFFormStyle.Wizard) {
    if (isNotNullAndUndefined(formPageComponent)) {
      let totalItems: number = formPageComponent && formPageComponent.length;
      let selectedTabIndex = tabObj && tabObj.selectedItem;
      return (
        <div className="section__container">
          <div className="section__div">
            <TabComponent
              cssClass="e-fill custom-tab-footer-border bp-tab active-bg-gray formLibraryTab borderBottom_tabHeader tab_fixedHeader_scrollableContent customTabDirective"
              ref={(tab) => (tabObj = tab)}
              animation={tabAnimationSettings}
              selecting={tabSelecting.bind(this)}
              created={tabCreated.bind(this)}
              height="100%"
            >
              <TabItemsDirective>
                {formPageComponent.map((pageComponent) => {
                  return (
                    <TabItemDirective
                      key={pageComponent.UID}
                      header={{ text: pageComponent.DisplayName }}
                      content={renderWizardTabContent}
                    />
                  );
                })}
              </TabItemsDirective>
            </TabComponent>
          </div>
          {/* {totalItems > 1 && (
            <div className="p-3 py-2 border-top bg-white">
              <div className="row g-1">
                <div className="col-auto ms-auto">
                  <ButtonComponent
                    type="button"
                    id="wizardPreviousBtn"
                    cssClass="e-outline"
                    isPrimary
                    iconCss="fas fa-chevrons-left"
                    disabled={selectedTabIndex === 0 ? true : false}
                    onClick={() => wizardBtnClicked("wizardPreviousBtn")}
                  >
                    Previous
                  </ButtonComponent>
                </div>
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    id="wizardNextBtn"
                    cssClass="e-outline"
                    isPrimary
                    disabled={selectedTabIndex === totalItems - 1 ? true : false}
                    iconCss="fas fa-chevrons-right"
                    onClick={() => wizardBtnClicked("wizardNextBtn")}
                    iconPosition="right"
                  >
                    Next
                  </ButtonComponent>
                </div>
              </div>
            </div>
          )} */}
        </div>
      );
    } else {
      return (
        <RAFFormComponentModelRender
          formDataFieldName={formDataFieldName}
          components={components}
          validateFields={
            isNotNullAndUndefined(props.validateFields)
              ? props.validateFields
              : true
          }
          isPreviewMode={props.isPreviewMode}
          isDisabled={props.isDisabled}
          allowSubmit={false}
          moduleName={moduleName}
          viewAttributes={viewAttributes}
          colClass="col-12"
          previewMode={props.previewMode}
        />
      );
    }
  } else {
    return (
      <RAFFormComponentModelRender
        formDataFieldName={formDataFieldName}
        components={components}
        validateFields={
          isNotNullAndUndefined(props.validateFields)
            ? props.validateFields
            : true
        }
        isPreviewMode={props.isPreviewMode}
        isDisabled={props.isDisabled}
        allowSubmit={false}
        moduleName={moduleName}
        viewAttributes={viewAttributes}
        colClass="col-12"
        previewMode={props.previewMode}
      />
    );
  }
}

export default RAFFormModelRenderer;
