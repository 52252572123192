import { RuleModel } from "@syncfusion/ej2-react-querybuilder";
import { atomFamily } from "recoil";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { RAFRRuleModel } from "../../../RAFComponents/models/Common/FormLibraryRow";

export class RAFFormLibraryTabName {
  static formLibraryConstant(Id: string, DisplayName: string, CollectionName: string, IconClass: string) {
      return { Id, DisplayName, CollectionName,IconClass };
  }

  static formLibrary_schedule = RAFFormLibraryTabName.formLibraryConstant('formLibrary_schedule', 'Schedule', 'Schedules','fal fa-calendar-edit');
  static formLibrary_records = RAFFormLibraryTabName.formLibraryConstant('formLibrary_records', 'Record', 'Completed Records',"fas fa-clipboard");
  static formLibrary_template = RAFFormLibraryTabName.formLibraryConstant('formLibrary_template', 'Template', 'Templates','fas fa-file-alt');
}

export const atomFormLibraryGridPageTabState = atomFamily<any, any>({
  key: "formLibraryGridPageTabState",
  default: null
});

export const atomSelectedFormLibraryUIDState = atomFamily<any, any>({
  key: "selectedFormLibraryUIDState",
  default: null
});

export function getSFRulesByRAFRRule(rule: RAFRRuleModel): RuleModel {
  let outVal: RuleModel = {};
  if (isNotNullAndUndefined(rule)) {
    if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
      outVal.condition = rule.Condition;
      outVal.rules = [];
      const ruleModels: RAFRRuleModel[] = rule.Rules;
      for (var i = 0; i < ruleModels.length; i++) {
        outVal.rules.push(getSFRulesByRAFRRule(ruleModels[i]));
      }
    } else if (
      isNotNullAndUndefined(rule.Value) &&
      isNotNullAndUndefined(rule.Value[0])
    ) {
      let objField: RuleModel = {};
      objField.field = rule.Field;
      objField.type = rule.Type;
      objField.operator = rule.Operator;
      objField.label = rule.Label;
      objField.not = rule.Not;
      if (
        isNotNullAndUndefined(rule.Value) &&
        isNotNullAndUndefined(rule.Value[0])
      )
        objField.value = rule.Value.length > 1 ? rule.Value : rule.Value[0];
      outVal = objField;
    }
  }
  return outVal;
}

export function getRAFRRuleBySFRules(rule: RuleModel): RAFRRuleModel {
  let outVal: RAFRRuleModel = {};
  if (isNotNullAndUndefined(rule)) {
    if (rule.rules && isNotNullAndUndefined(rule.rules)) {
      //outVal.Condition = (rule.condition === "and") ? RAFOperator.AndCondition : RAFOperator.OrCondition;
      outVal.Condition = rule.condition;
      outVal.Rules = [];
      const ruleModels: RuleModel[] = rule.rules;
      for (var i = 0; i < ruleModels.length; i++) {
        outVal.Rules.push(getRAFRRuleBySFRules(ruleModels[i]));
      }
    } else if (isNotNullAndUndefined(rule.value)) {
      let objField: RAFRRuleModel = {};
      objField.Field = rule.field;
      objField.Type = rule.type;
      objField.Operator = rule.operator;
      objField.Value = rule.value;
      objField.Label = rule.label;
      objField.Not = rule.not;
      outVal = objField;
    }
  }

  return outVal;
}
