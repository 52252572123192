import { collection, getDocs, initializeFirestore, limit, query, where } from "firebase/firestore";
import { msalInstance } from "../../..";
import { getFBSuffix, getSessionStorage, setSessionStorage } from "../../../RAFComponents/helpers/AppHelper";
import { ContentType, RAFAttributeName, StorageKey } from "../../../constants/Common/Constants";
import { Guid, isConnectedToInternet, isNotNullAndUndefined, isNullOrUndefined, IsNullOrWhiteSpace, propertyOf } from "../../../RAFComponents/helpers/utils";
import { AttributeRow } from "../../../RAFComponents/models/Common/AttributeRow";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { RAFPageBuilderColumn, RAFPageBuilderComponent, RAFPageBuilderLayout, RAFPageBuilderRow } from '../../../RAFComponents/models/Common/RAFPageBuilderModel';
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { RAFCreateAction, RAFEmailAction, RAFProcessAction, RAFSMSAction } from "../BusinessRules/BusinessAction";
import { ColumnField, PageLayoutJM, RAFComponentFormModel, RAFComponentHeadingModel, RAFComponentImageModel, RAFComponentInputModel, RAFComponentRichTextModel, RAFComponentSignatureModel, RAFComponentVideoModel, SectionRow } from "./PageLayoutJM";
import { COLUMN, COMPONENT, ROW } from '../../../constants/Common/RAFPageBuilderConstants';
import { IPageLayoutDto } from "./PageLayoutRow";
import { ConvertPageLayoutJMDtoToPageLayoutJM } from "./PageLayoutMappingRegister";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../RAFFirebase";

export function convertAttributesToPageLayout(attributeRow: AttributeRow[], displayName?: string): RAFPageBuilderLayout {
    let rafPageBuilderLayout: RAFPageBuilderLayout = {};
    rafPageBuilderLayout.layout = [];
    let firstRow: RAFPageBuilderRow = {
        id: Guid.newGuid(),
        type: ROW,
        Title: `${displayName} Information`,
        children: []
    };

    let firstColumn: RAFPageBuilderColumn = {
        id: Guid.newGuid(),
        type: COLUMN,
        children: []
    };
    if (isNotNullAndUndefined(attributeRow) && attributeRow.length > 0) {
        for (var i = 0; i < attributeRow.length; i++) {
            const item: AttributeRow = attributeRow[i];
            let rafPageBuilderComponent: RAFPageBuilderComponent = {};
            rafPageBuilderComponent.Title = item.DisplayName;
            rafPageBuilderComponent.Name = item.Name;
            rafPageBuilderComponent.id = item.UID;
            rafPageBuilderComponent.ComponentType = item.DataType;
            rafPageBuilderComponent.type = COMPONENT;
            rafPageBuilderComponent.Hidden = !item.Visible;
            rafPageBuilderComponent.IsRequired = item.AttributeSettings && item.AttributeSettings.IsRequired;
            rafPageBuilderComponent.ValueJson = item.ValueJson;
            rafPageBuilderComponent.IsReadonly = item.AttributeSettings && item.AttributeSettings.IsReadonly;
            rafPageBuilderComponent.IsPrimary = item.AttributeSettings && item.AttributeSettings.IsPrimary;
            rafPageBuilderComponent.Searchable = item.Searchable;
            rafPageBuilderComponent.ShowInFilter = item.ShowInFilter;
            rafPageBuilderComponent.ShowInView = item.ShowInView;
            rafPageBuilderComponent.DefaultValue = item.AttributeSettings && item.AttributeSettings.DefaultValue;
            rafPageBuilderComponent.UIType = item.AttributeSettings && item.AttributeSettings.UIType;
            //rafPageBuilderComponent.DisplayStyle = item.AttributeSettings && item.AttributeSettings.DisplayStyle;
            rafPageBuilderComponent.InlineEdit = item.InlineEdit;
            rafPageBuilderComponent.EntityUID = item.EntityUID;
            rafPageBuilderComponent.RelatedEntities = item.RelatedEntities;
            rafPageBuilderComponent.Multiselect = item.Multiselect;
            firstColumn.children.push(rafPageBuilderComponent);
        }
    }
    firstRow.children.push(firstColumn);
    rafPageBuilderLayout.layout.push(firstRow);
    return rafPageBuilderLayout;
}

export function getAttributesFromPageLayout(layout: RAFPageBuilderLayout): AttributeRow[] {
    let rafPageBuilderComponents: RAFPageBuilderComponent[] = layout.layout[0].children[0].children;
    let retVal: AttributeRow[] = [];
    if (isNotNullAndUndefined(rafPageBuilderComponents) && rafPageBuilderComponents.length > 0) {
        for (var i = 0; i < rafPageBuilderComponents.length; i++) {
            const item: RAFPageBuilderComponent = rafPageBuilderComponents[i];
            let attrib: AttributeRow = {};
            attrib.UID = item.id;
            attrib.DisplayName = item.Title;
            attrib.Name = item.Name;
            attrib.DataType = item.ComponentType;
            //attrib.type = COMPONENT;
            attrib.Visible = !item.Hidden;
            attrib.AttributeSettings = {};
            attrib.AttributeSettings.IsRequired = item.IsRequired;
            attrib.AttributeSettings.IsReadonly = item.IsReadonly;
            attrib.AttributeSettings.IsPrimary = item.IsPrimary;
            attrib.Searchable = item.Searchable;
            attrib.ShowInFilter = item.ShowInFilter;
            attrib.ShowInView = item.ShowInView;
            attrib.AttributeSettings.DefaultValue = item.DefaultValue;
            attrib.AttributeSettings.UIType = item.UIType;
            attrib.InlineEdit = item.InlineEdit;
            attrib.ValueJson = item.ValueJson;
            attrib.EntityUID = item.EntityUID;
            attrib.Multiselect = item.Multiselect;
            attrib.DisplayOrder = i + 1.;
            attrib.RelatedEntities = item.RelatedEntities;
            retVal.push(attrib);
        }
    }
    return retVal;
}

export function convertAttributesToPageLayoutJM(displayName?: string, entityUID?: string, pageLayoutJM?: PageLayoutJM, viewAttributes?: QueryAttributeJM[]): PageLayoutJM {

    let newSectionRow: SectionRow = new SectionRow();
    newSectionRow.UID = Guid.newGuid();
    newSectionRow.Name = "Basic information";
    newSectionRow.DisplayOrder = 1;
    newSectionRow.IsCollapsed = false;
    newSectionRow.ShowHeader = false;
    newSectionRow.ColumnCount = 1;
    newSectionRow.Columns = [];
    let columnFields: ColumnField[] = [];

    let formFields = isNotNullAndUndefined(viewAttributes)
        ? viewAttributes.filter(
            (x) =>
                x.AttributeName !== RAFAttributeName.RecordInfo &&
                x.AttributeName !== RAFAttributeName.UID &&
                x.AttributeName !== RAFAttributeName.Entity &&
                x.AttributeName !== RAFAttributeName.FormLibrary &&
                x.AttributeName !== RAFAttributeName.TagsListJson &&
                x.AttributeName !== RAFAttributeName.TagsListJsonText &&
                x.AttributeName !== RAFAttributeName.Portal &&
                x.ShowInSelect === true
        )
        : null;

    if (isNotNullAndUndefined(formFields) && formFields.length > 0) {
        formFields.forEach((attrib) => {
            columnFields.push({
                AttributeUID: attrib.AttributeUID,
                DataType: attrib.DataType,
                FieldName: attrib.PropertyName,
                DisplayName: attrib.DisplayName,
                IsEditable: attrib.IsEditable,
                IsHidden: attrib.Visible === false ? true : false,
                IsRequired: attrib.IsRequired,
                DefaultValue: attrib.DefaultValue,
                UIType: attrib.UIType,
                ValueJson: attrib.ValueJson,
                RelatedEntity: attrib.RelatedEntities,
                UISettings: attrib.UISettings,
            });
        });
    }
    newSectionRow.Columns.push({
        Fields: columnFields,
        UID: Guid.newGuid(),
    });
    let sectionRow: SectionRow[] = [];
    sectionRow.push(newSectionRow);

    pageLayoutJM.Layout = { sections: sectionRow };
    if (isNullOrUndefined(pageLayoutJM)) {
        pageLayoutJM.EntityUID = entityUID;
        pageLayoutJM.PageType = RAFPageType.Default;
        pageLayoutJM.Name = RAFPageType.Default;
    }
    return pageLayoutJM;
}

export async function getPageLayoutByEntityIdAndPageType(entityId?: string, rafPageType?: string): Promise<PageLayoutJM> {
    const isOnline = await isConnectedToInternet();
    // if (isOnline === true) {
    return getPageLayoutByEntityIdAndPageTypeAPI(entityId, rafPageType);
    // }
    // else {
    //     return getPageLayoutByEntityIdAndPageTypeFB(entityId, rafPageType);
    // }
}

export function getPageLayoutByEntityIdAndPageTypeAPI(entityId?: string, rafPageType?: string) {
    return new Promise<PageLayoutJM>((resolve) => {
        let requestData: any = {
            EntityUID: entityId,
        };
        if (!IsNullOrWhiteSpace(rafPageType)) {
            requestData.PageType = rafPageType;
        }

        return repositoryActions.postDataAndGetResponse(`PageLayout/RetrieveByPageType`,
            requestData,
            null,
            ContentType.applicationJson)
            .then((listResponse) => {
                let objPageLayoutJM: PageLayoutJM = null;

                if (isNotNullAndUndefined(listResponse) && isNotNullAndUndefined(listResponse.data) && isNotNullAndUndefined(listResponse.data.Entity)) {
                    objPageLayoutJM = listResponse.data.Entity;
                }
                if (isNotNullAndUndefined(objPageLayoutJM) && isNotNullAndUndefined(objPageLayoutJM.UID)) {
                    setSessionStorage(StorageKey.objPageLayout_UID + objPageLayoutJM.UID, true, JSON.stringify(objPageLayoutJM));
                    getPageLayoutByUID(objPageLayoutJM.UID).
                        then((pageLayoutJM) => {
                            resolve(pageLayoutJM);
                        }).catch(error => error);

                }
                else {
                    resolve(null);
                }
            }).catch(error => error);
    });
}

export async function getPageLayoutByEntityIdAndPageTypeFB(entityId?: string, rafPageType?: string) {
    debugger;
    //const REACT_APP_FB_SUFFIX: string = isNotNullAndUndefined(process.env.REACT_APP_FB_SUFFIX) ? process.env.REACT_APP_FB_SUFFIX : "";
    const REACT_APP_FB_SUFFIX = getFBSuffix();
    let objPageLayoutJM: PageLayoutJM = null;
    const app = initializeApp(firebaseConfig);
    const firestoreDatabase = initializeFirestore(app, {
        ignoreUndefinedProperties: true,
    });
    const q = query(collection(firestoreDatabase, `tenants${REACT_APP_FB_SUFFIX}/${msalInstance.currentTenantId}/page_layouts`), where("EntityUID", "==", entityId), where("PageType", "==", rafPageType), limit(1));
    const entitiesSnapshot = await getDocs(q);
    entitiesSnapshot.forEach((doc1) => {
        objPageLayoutJM = {
            ...doc1.data(),
            //CreatedDate: doc1.get(propertyOf<UserFeedRow>("CreatedDate")).toDate()
        } as PageLayoutJM;
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc1);
        //console.log(doc1.id, " => ", doc1.data());
    });

    return objPageLayoutJM;
}

export async function getAllPageLayoutsFB() {
    debugger;
    //const REACT_APP_FB_SUFFIX: string = isNotNullAndUndefined(process.env.REACT_APP_FB_SUFFIX) ? process.env.REACT_APP_FB_SUFFIX : "";
    const REACT_APP_FB_SUFFIX = getFBSuffix();
    let pageLayoutList: PageLayoutJM[] = [];
    const app = initializeApp(firebaseConfig);
    const firestoreDatabase = initializeFirestore(app, {
        ignoreUndefinedProperties: true,
    });
    const q = query(collection(firestoreDatabase, `tenants${REACT_APP_FB_SUFFIX}/${msalInstance.currentTenantId}/page_layouts`));
    const entitiesSnapshot = await getDocs(q);
    entitiesSnapshot.forEach((doc1) => {
        pageLayoutList.push({
            ...doc1.data(),
            //CreatedDate: doc1.get(propertyOf<EntityRow>("CreatedDate")).toDate()
        } as PageLayoutJM);
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc1);
        //console.log(doc1.id, " => ", doc1.data());
    });

    return pageLayoutList;
}

export function getPageLayoutByPageTypeAndEntity(entityId?: string, rafPageType?: string) {
    return new Promise<PageLayoutJM>((resolve) => {
        const pageLayoutListEntityStorage = getSessionStorage(StorageKey.pageLayoutList_pageTypeentity + rafPageType + entityId, true);
        let pageLayoutList: PageLayoutJM[] = isNotNullAndUndefined(pageLayoutListEntityStorage) &&
            !IsNullOrWhiteSpace(pageLayoutListEntityStorage) &&
            isNotNullAndUndefined(JSON.parse(pageLayoutListEntityStorage)) ? JSON.parse(pageLayoutListEntityStorage) : null;

        if (isNotNullAndUndefined(pageLayoutList) && pageLayoutList.length > 0 && isNotNullAndUndefined(pageLayoutList[0].UID)) {
            getPageLayoutByUID(pageLayoutList[0].UID).then((retrieveResponse) => {
                let pageLayoutJM: PageLayoutJM = retrieveResponse;
                resolve(pageLayoutJM);
            }).catch(error => error);
        } else {
            const listRequest: ListServiceRequest = new ListServiceRequest();
            let relatedFilter: RAFCustomFilter = {};
            relatedFilter.Condition = "and";
            relatedFilter.Rules = [];
            if (!IsNullOrWhiteSpace(entityId)) {
                let entityFilter: RAFCustomFilter = {};
                let entityFilterVal: string[] = [];
                entityFilterVal.push(entityId);
                entityFilter.Operator = RAFCustomOperator.Equal;
                entityFilter.Value = entityFilterVal;
                entityFilter.Field = propertyOf<PageLayoutJM>("EntityUID");
                relatedFilter.Rules.push(entityFilter);

                if (!IsNullOrWhiteSpace(rafPageType)) {
                    let pageTypeFilter: RAFCustomFilter = {};
                    let pageTypeFilterVal: string[] = [];
                    pageTypeFilterVal.push(rafPageType);
                    pageTypeFilter.Operator = RAFCustomOperator.Equal;
                    pageTypeFilter.Value = pageTypeFilterVal;
                    pageTypeFilter.Field = propertyOf<PageLayoutJM>("PageType");
                    relatedFilter.Rules.push(pageTypeFilter);
                }
            }
            else {
                relatedFilter = null;
            }

            listRequest.CustomFilter = relatedFilter;
            listRequest.Skip = 0;
            listRequest.Take = 0;


            return repositoryActions.postDataAndGetResponse(`PageLayout/List`,

                listRequest,
                null,
                ContentType.applicationJson)
                .then((listResponse) => {
                    let pageLayoutList: PageLayoutJM[] = [];

                    if (isNotNullAndUndefined(listResponse) && isNotNullAndUndefined(listResponse.data) && isNotNullAndUndefined(listResponse.data.Entities)) {
                        pageLayoutList = listResponse.data.Entities;
                    }
                    if (isNotNullAndUndefined(pageLayoutList) && pageLayoutList.length > 0 && isNotNullAndUndefined(pageLayoutList[0].UID)) {
                        setSessionStorage(StorageKey.pageLayoutList_pageTypeentity + rafPageType + entityId, true, JSON.stringify(pageLayoutList));
                        getPageLayoutByUID(pageLayoutList[0].UID).then((retrieveResponse) => {


                            const pageLayoutDto: IPageLayoutDto = retrieveResponse;
                            const pageLayoutJM: PageLayoutJM = ConvertPageLayoutJMDtoToPageLayoutJM(pageLayoutDto);

                            resolve(pageLayoutJM);
                        }).catch(error => error);
                    }
                    else {
                        resolve(null);
                    }
                }).catch(error => error);
        }
    });
}

export function getPageLayoutByUID(pagelayoutUID?: string) {
    return new Promise<PageLayoutJM>((resolve) => {
        if (isNotNullAndUndefined(pagelayoutUID)) {
            const objPageLayout_UID = getSessionStorage(StorageKey.objPageLayout_UID + pagelayoutUID, true);
            let objPageLayoutJM: PageLayoutJM = isNotNullAndUndefined(objPageLayout_UID) &&
                !IsNullOrWhiteSpace(objPageLayout_UID) &&
                isNotNullAndUndefined(JSON.parse(objPageLayout_UID)) ? JSON.parse(objPageLayout_UID) : null;
            if (isNotNullAndUndefined(objPageLayoutJM) && isNotNullAndUndefined(objPageLayoutJM.UID)) {
                resolve(objPageLayoutJM);
            } else {
                return repositoryActions.postDataAndGetResponse(`PageLayout/Retrieve`,
                    {
                        "EntityId": pagelayoutUID,
                    },
                    null,
                    ContentType.applicationJson)
                    .then((retrieveResponse) => {
                        if (isNotNullAndUndefined(retrieveResponse) && isNotNullAndUndefined(retrieveResponse.data) && isNotNullAndUndefined(retrieveResponse.data.Entity)) {
                            setSessionStorage(StorageKey.objPageLayout_UID + pagelayoutUID, true, JSON.stringify(retrieveResponse.data.Entity));
                            resolve(retrieveResponse.data.Entity);
                        } else {
                            resolve(null);
                        }
                    }).catch(error => error);
            }
        } else {
            resolve(null);
        }
    });
}

export function isRAFComponentFormModel(obj: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentFormModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentInputModel): obj is RAFComponentFormModel {
    return (obj as RAFComponentFormModel).DataType === RAFDataType.Module || (obj as RAFComponentFormModel).DataType === RAFDataType.Form || (obj as RAFComponentFormModel).DataType === RAFDataType.Component || (obj as RAFComponentFormModel).DataType === RAFDataType.FormTemplate || (obj as RAFComponentFormModel).DataType === RAFDataType.EmbedForm || (obj as RAFComponentFormModel).DataType === RAFDataType.LinkForm;
}

export function isRAFComponentHeadingModel(obj: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentFormModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentInputModel): obj is RAFComponentHeadingModel {
    return (obj as RAFComponentHeadingModel).DataType === RAFDataType.Heading;
}

export function isRAFComponentVideoModel(obj: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentFormModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentInputModel): obj is RAFComponentVideoModel {
    return (obj as RAFComponentVideoModel).DataType === RAFDataType.Video;
}
export function isRAFComponentImageModel(obj: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentFormModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentInputModel): obj is RAFComponentImageModel {
    return (obj as RAFComponentImageModel).DataType === RAFDataType.Image;
}
export function isRAFComponentRichTextModel(obj: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentFormModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentInputModel): obj is RAFComponentRichTextModel {
    return (obj as RAFComponentRichTextModel).DataType === RAFDataType.RichText;
}

export function isRAFComponentSignatureModel(obj: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentFormModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentInputModel): obj is RAFComponentSignatureModel {
    return (obj as RAFComponentSignatureModel).DataType === RAFDataType.Signature;
}

export function isRAFComponentInputModel(obj: RAFComponentHeadingModel | RAFComponentVideoModel | RAFComponentFormModel | RAFComponentImageModel | RAFComponentRichTextModel | RAFComponentSignatureModel | RAFComponentInputModel): obj is RAFComponentInputModel {
    return (obj as RAFComponentInputModel).DataType === RAFDataType.Text || (obj as RAFComponentInputModel).DataType === RAFDataType.Dropdown
        || (obj as RAFComponentInputModel).DataType === RAFDataType.Boolean || (obj as RAFComponentInputModel).DataType === RAFDataType.Date
        || (obj as RAFComponentInputModel).DataType === RAFDataType.DateTime || (obj as RAFComponentInputModel).DataType === RAFDataType.Password
        || (obj as RAFComponentInputModel).DataType === RAFDataType.Lookup || (obj as RAFComponentInputModel).DataType === RAFDataType.AutoNumber
        || (obj as RAFComponentInputModel).DataType === RAFDataType.Formula || (obj as RAFComponentInputModel).DataType === RAFDataType.Number;
}


export function isRAFSMSAction(obj: RAFSMSAction | RAFEmailAction | RAFCreateAction | RAFProcessAction): obj is RAFSMSAction {
    return (obj as RAFSMSAction).Type === RAFDataType.SMS;
}

export function isRAFEmailAction(obj: RAFSMSAction | RAFEmailAction | RAFCreateAction | RAFProcessAction): obj is RAFEmailAction {
    return (obj as RAFEmailAction).Type === RAFDataType.Email;
}