import DOMPurify from "dompurify";
import { PropsWithChildren } from "react";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import {
  RAFDataType,
  RAFUIType,
} from "../../../RAFComponents/models/Common/RAFDataType";
import RAFEntityProvider from "../../../RAFModules/Common/Providers/RAFEntityProvider";
import RAFPageLayoutProvider from "../../../RAFModules/Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../../RAFModules/Common/RAFPageLayout";
import { RAFDataModelRow } from "../RAFPage/PageLayoutJM";
import RAFFormInputModelRender from "./RAFFormInputModelRender";
import { FormRenderProps } from "react-final-form";
import { RAFFormContext } from "../../../RAFComponents/Inputs/RFFUtils";
import React from "react";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import { RAFLayout } from "../../../constants/Common/Constants";

interface IProps {
  formDataFieldName?: string;
  formDataModelFieldName?: string;
  components?: RAFDataModelRow[];
  validateFields?: boolean;
  isPreviewMode?: boolean;
  allowSubmit?: boolean;
  autoSave?: boolean;
  isDisabled?: boolean;
  colClass?: string;
  moduleName?: string;
  viewAttributes?: QueryAttributeJM[];
  previewMode?: "Mobile" | "Desktop";
}

function RAFFormComponentModelRender({
  components,
  moduleName,
  viewAttributes,
  ...props
}: PropsWithChildren<IProps>) {
  const isDisabled = props.isDisabled ?? false;
  return (
    <>
      {components &&
        components.map((item, index) => {
          let formItemWidth =
            isNotNullAndUndefined(item["Width"]) &&
              window.innerWidth > 576 &&
              props.previewMode !== "Mobile"
              ? item["Width"]
              : null;
          let formItemMinWidth =
            isNotNullAndUndefined(item["Width"]) &&
              window.innerWidth > 576 &&
              props.previewMode !== "Mobile"
              ? item["Width"]
              : null;
          let formItemMaxWidth =
            isNotNullAndUndefined(item["MaxWidth"]) &&
              window.innerWidth > 576 &&
              props.previewMode !== "Mobile"
              ? item["MaxWidth"]
              : null;
          let formItemTitleLocation = item["TitleLocation"];
          let formItemDisplayInNewLine =
            isNotNullAndUndefined(item["DisplayInNewLine"]) &&
              window.innerWidth > 576 &&
              props.previewMode !== "Mobile"
              ? item["DisplayInNewLine"]
              : null;

          let outerDivClass;
          if (
            IsNullOrWhiteSpace(formItemTitleLocation) ||
            formItemTitleLocation === "Top"
          ) {
            outerDivClass =
              isNotNullAndUndefined(item.AttributeSettings) &&
                isNotNullAndUndefined(item.AttributeSettings.Render) &&
                item.AttributeSettings.Render === false
                ? `${props.colClass} hidden`
                : `${props.colClass}`;
          } else {
            outerDivClass =
              isNotNullAndUndefined(item.AttributeSettings) &&
                isNotNullAndUndefined(item.AttributeSettings.Render) &&
                item.AttributeSettings.Render === false
                ? `${props.colClass} mt-4 hidden`
                : `${props.colClass} mt-4`;
          }

          return (
            <div
              className={outerDivClass}
              id={"formItemDiv_" + item.UID}
              key={"formItem_" + item.UID + isDisabled.toString()}
              style={{
                width:
                  isNotNullAndUndefined(formItemWidth) &&
                    !formItemDisplayInNewLine
                    ? formItemWidth
                    : "100%",
                ...(isNotNullAndUndefined(formItemMinWidth) &&
                  !formItemDisplayInNewLine
                  ? { minWidth: formItemMinWidth }
                  : {}),
                ...(isNotNullAndUndefined(formItemMaxWidth) &&
                  !formItemDisplayInNewLine
                  ? { maxWidth: formItemMaxWidth }
                  : {}),
                // ...(isNotNullAndUndefined(formItemDisplayInNewLine)
                //   ? { flex: "1 0" }
                //   : {}),
              }}
            >
              <div
                style={{
                  width:
                    isNotNullAndUndefined(formItemWidth) &&
                      formItemDisplayInNewLine
                      ? formItemWidth
                      : "100%",
                  ...(isNotNullAndUndefined(formItemMinWidth) &&
                    formItemDisplayInNewLine
                    ? { minWidth: formItemMinWidth }
                    : {}),
                  ...(isNotNullAndUndefined(formItemMaxWidth) &&
                    formItemDisplayInNewLine
                    ? { maxWidth: formItemMaxWidth }
                    : {}),
                }}
              >
                {item.DataType === RAFDataType.Page && item.Visible === true ? (
                  <div
                    className={
                      isNotNullAndUndefined(item.ShowHeader) &&
                        item.ShowHeader === true
                        ? "e-card bp-card bp-page p-0 mb-3 cardPanel"
                        : "e-card bp-card bp-page p-0 mb-3 cardPanel border-0"
                    }
                  >
                    <div className="e-card-content p-0">
                      <div className="formLibrary__content">
                        {isNotNullAndUndefined(item.ShowHeader) &&
                          item.ShowHeader === true && (
                            <div className="d-flex justify-content-between">
                              <div className="sectionHeader">
                                <span className="m-0">{item.DisplayName}</span>
                              </div>
                            </div>
                          )}
                        <div className="row g-3 justify-content-end">
                          <RAFFormComponentModelRender
                            formDataFieldName={props.formDataFieldName}
                            components={item.Components}
                            validateFields={
                              isNotNullAndUndefined(props.validateFields)
                                ? props.validateFields
                                : true
                            }
                            isPreviewMode={props.isPreviewMode}
                            isDisabled={props.isDisabled}
                            allowSubmit={false}
                            moduleName={moduleName}
                            viewAttributes={viewAttributes}
                            previewMode={props.previewMode}
                            colClass="col-12"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : item.DataType === RAFDataType.Panel ? (
                  <div className={item.ShowHeader === true ? "e-card p-0" : ""}>
                    <RAFCollapseWithPlusIcon
                      toggleArrowIcon="Arrow"
                      title={item.DisplayName}
                      collapsePanelHeaderSpanClass="subtitle_2"
                      // collapsePanelHeaderClass={"collapsePanel__header__fixedHeight__lg"}
                      allowFullRowClick
                      showSeparator={true}
                      isCollapsed={item.IsCollapsed}
                      collapsible={true}
                      showHeader={item.ShowHeader}
                      layout={RAFLayout.OneColumnLayout}
                      collapsePanelContentClassname={item.ShowHeader ? '' : 'p-0'}
                    >
                      <RAFFormComponentModelRender
                        formDataFieldName={props.formDataFieldName}
                        components={item.Components}
                        validateFields={
                          isNotNullAndUndefined(props.validateFields)
                            ? props.validateFields
                            : true
                        }
                        isPreviewMode={props.isPreviewMode}
                        isDisabled={props.isDisabled}
                        allowSubmit={false}
                        // colClass={
                        //   item.ColumnCount
                        //     ? props.previewMode === "Mobile"
                        //       ? `col-12`
                        //       : `col-md-${12 / item.ColumnCount}`
                        //     : props.previewMode === "Mobile"
                        //     ? `col-12`
                        //     : `col-md-6`
                        // }
                        // colClass={`col-md-12`}
                        moduleName={moduleName}
                        viewAttributes={viewAttributes}
                        previewMode={props.previewMode}
                      />
                    </RAFCollapseWithPlusIcon>
                  </div>
                ) : item.DataType === RAFDataType.Column ? (
                  <div className="bp-card bp-page sectionColumn">
                    <div className="row g-1 g-sm-2 g-lg-3 gy-3 gy-sm-3">
                      <RAFFormComponentModelRender
                        formDataFieldName={props.formDataFieldName}
                        components={item.Components}
                        validateFields={
                          isNotNullAndUndefined(props.validateFields)
                            ? props.validateFields
                            : true
                        }
                        isPreviewMode={props.isPreviewMode}
                        isDisabled={props.isDisabled}
                        allowSubmit={false}
                        colClass="col-12"
                        moduleName={moduleName}
                        viewAttributes={viewAttributes}
                        previewMode={props.previewMode}
                      />
                    </div>
                  </div>
                ) : item.DataType === RAFDataType.Paragraph ? (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: isNotNullAndUndefined(item.DisplayName)
                          ? DOMPurify.sanitize(item.DisplayName)
                          : undefined,
                      }}
                    ></div>
                  </div>
                ) : item.DataType === RAFDataType.SubForm ? (
                  <div>
                    <RAFEntityProvider moduleName={item.RelatedEntities}>
                      <RAFAttributeRelatedListProvider
                        moduleName={item.RelatedEntities}
                      >
                        <RAFPageLayoutProvider
                          prefix={"SubForm" + item.RelatedEntities}
                        >
                          <RAFPageLayout validateFields={true} />
                        </RAFPageLayoutProvider>
                      </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                  </div>
                ) : item.DataType === RAFDataType.Lookup &&
                  isNotNullAndUndefined(item.AttributeSettings) &&
                  isNotNullAndUndefined(item.AttributeSettings.UIType) &&
                  item.AttributeSettings.UIType === RAFUIType.DynamicForm ? (
                  <div>
                    <RAFEntityProvider moduleName={item.RelatedEntities}>
                      <RAFAttributeRelatedListProvider
                        moduleName={item.RelatedEntities}
                      >
                        <RAFPageLayoutProvider
                          prefix={"DynamicForm_" + item.RelatedEntities}
                        >
                          <RAFPageLayout validateFields={true} />
                        </RAFPageLayoutProvider>
                      </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                  </div>
                ) : item.DataType === RAFDataType.Lookup &&
                  isNotNullAndUndefined(item.AttributeSettings) &&
                  isNotNullAndUndefined(item.AttributeSettings.UIType) &&
                  item.AttributeSettings.UIType === RAFDataType.PrimaryForm ? (
                  <></>
                ) : (
                  <RAFFormInputModelRender
                    formDataFieldName={props.formDataFieldName}
                    rafComponent={item}
                    validateFields={props.validateFields}
                    isPreviewMode={props.isPreviewMode}
                    isDisabled={props.isDisabled}
                    allowSubmit={props.allowSubmit}
                    moduleName={moduleName}
                    viewAttributes={viewAttributes}
                    showLabel
                  />
                )}
              </div>
            </div>
          );
        })}
    </>
  );
}

export default RAFFormComponentModelRender;
