import React, { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import {
  getFormValue,
  RAFFormContext,
} from "../../../RAFComponents/Inputs/RFFUtils";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import { RAFFormFieldName } from "../../../constants/Common/RMConstants";
import {
  RAFDataModelRow,
  RAFFormIOModel,
} from "../RAFPage/PageLayoutJM";
import RAFIOAssesmentModelRender from "./RAFIOAssesmentModelRender";

interface IProps {
  formDataFieldName?: string;
  formDataModelFieldName?: string;
  componentModel?: RAFFormIOModel;
  validateFields?: boolean;
  allowSubmit?: boolean;
  autoSave?: boolean;
  isDisabled?: boolean;
}

function RAFIOCompmentModelRender({ ...props }: PropsWithChildren<IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  let components: RAFFormIOModel = isNotNullAndUndefined(props.componentModel)
    ? props.componentModel
    : getFormValue(rafFormContextValue, props.formDataModelFieldName);

  const renderFormItem = () => {
    let compoenntJson =
      isNotNullAndUndefined(components) &&
        isNotNullAndUndefined(components.Components)
        ? components.Components
        : null;

    // const refresh = () => {
    //   let compoenntJson: RAFDataModelRow[] = [
    //     {
    //       FieldName: "panel1",
    //       DisplayName: "Panel1",
    //       InputType: null,
    //       DataType: RAFDataType.Panel,
    //       ValuesJson: null,
    //       IsHidden: null,
    //       DefaultValue: null,
    //       Placeholder: null,
    //       IsMultiple: null,
    //       IsRequired: null,
    //       Components: [
    //         {
    //           FieldName: "first_name",
    //           DisplayName: "Firstname",
    //           InputType: "text",
    //           DataType: RAFDataType.Text,
    //           ValuesJson: null,
    //           IsHidden: null,
    //           DefaultValue: null,
    //           Placeholder: null,
    //           IsMultiple: null,
    //           IsRequired: true,
    //           Components: [],
    //         },
    //         {
    //           FieldName: "last_name",
    //           DisplayName: "Lastname",
    //           InputType: "text",
    //           DataType: RAFDataType.Text,
    //           ValuesJson: null,
    //           IsHidden: null,
    //           DefaultValue: null,
    //           Placeholder: null,
    //           IsMultiple: null,
    //           IsRequired: true,
    //           Components: [],
    //         },
    //         {
    //           FieldName: "dob",
    //           DisplayName: "DOB",
    //           InputType: null,
    //           DataType: RAFDataType.Date,
    //           ValuesJson: null,
    //           IsHidden: false,
    //           DefaultValue: null,
    //           Placeholder: null,
    //           IsMultiple: false,
    //           IsRequired: false,
    //           Components: [],
    //         },
    //       ],
    //     },
    //     {
    //       FieldName: "panel2",
    //       DisplayName: "Panel2",
    //       InputType: null,
    //       DataType: RAFDataType.Panel,
    //       ValuesJson: null,
    //       IsHidden: null,
    //       DefaultValue: null,
    //       Placeholder: null,
    //       IsMultiple: null,
    //       IsRequired: null,
    //       Components: [
    //         {
    //           FieldName: "last_name",
    //           DisplayName: "Lastname",
    //           InputType: "text",
    //           DataType: RAFDataType.Text,
    //           ValuesJson: null,
    //           IsHidden: null,
    //           DefaultValue: null,
    //           Placeholder: null,
    //           IsMultiple: null,
    //           IsRequired: true,
    //           Components: [],
    //         },
    //         {
    //           FieldName: "dob",
    //           DisplayName: "DOB",
    //           InputType: null,
    //           DataType: RAFDataType.Date,
    //           ValuesJson: null,
    //           IsHidden: false,
    //           DefaultValue: null,
    //           Placeholder: null,
    //           IsMultiple: false,
    //           IsRequired: false,
    //           Components: [],
    //         },
    //       ],
    //     },
    //   ];
    //   if (this._isMounted) {
    //     this.setState({ compoenntJson });
    //   }
    // };

    return (
      <div className="col-12">
        <div className="row g-0 gy-3">
          {compoenntJson &&
            compoenntJson.map((item, index) => {
              let cssClass = "col-12 hidden";
              if (
                item.Name !== RAFFormFieldName.BusinessProcesss &&
                item.Name !== RAFFormFieldName.BusinessProcesssUID &&
                item.Name !== RAFFormFieldName.BusinessProcesssStep &&
                item.Name !== RAFFormFieldName.BusinessProcesssStepUID
              ) {
                cssClass = "col-12";
              }
              return (
                <div className={cssClass} key={index} id={"div_" + index}>
                  <RAFCollapse
                    IsCollapsed={false}
                    //title={item.DisplayName}
                    //customTitle={item.DisplayName}
                    headerRowClassName="bp-header bp-header-index bp-header-height-auto hover-parent-div hidden"
                    removeContentCardPadding
                    Collapsible={false}
                    fullRowClick={false}
                  >
                    <div className="w-100">
                      <div className="row g-0 gy-3">
                        {isNotNullAndUndefined(item.Components) &&
                          renderChildComponents(
                            item.Components,
                            `${props.formDataModelFieldName}.Components[${index}].Components`
                          )}
                      </div>
                    </div>
                  </RAFCollapse>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderChildComponents = (
    components: RAFDataModelRow[],
    field: string
  ) => {
    return (
      components &&
      components.map((item, index) => {
        return (
          <div className="col-12" key={index} id={"div_" + index}>
            <RAFIOAssesmentModelRender
              formDataModelFieldName={`${field}[${index}]`}
              formDataFieldName={props.formDataFieldName}
              rafComponents={item}
              validateFields={props.validateFields}
              allowSubmit={props.allowSubmit}
              isDisabled={props.isDisabled}
            />
          </div>
        );
      })
    );
  };

  if (isNotNullAndUndefined(components)) {
    return renderFormItem();
  } else {
    return null;
  }
}

export default RAFIOCompmentModelRender;
