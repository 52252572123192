import { propertyOf } from "../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../RAFComponents/models/Common/RAFEntityBase";

export class IFormDataDto {
    UID?: string;
    Title?: string;
    Category?: string;
    Entity?: string;
    IconName?: string;
    DataJson?: object;
    TagsListJson?: string[];
    //TagsListJsonText?: string;
    FormLibraryUID?: string;
    FormLibrary?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class IFormDataRow{
    UID?: string;
    Title?: string;
    Category?: string;
    Entity?: string;
    IconName?: string;
    DataJson?: object;
    TagsListJson?: string[];
    //TagsListJsonText?: string;
    FormLibraryUID?: string;
    FormLibrary?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class FormDataRow extends IFormDataRow implements RAFEntityBase {
    getClassName?() {
        return 'form_data';
    }
    getIdField?(): string {
        return propertyOf<FormDataRow>("Title");
    }
    getListUrl?(): string {
        return "FormData/List";
    }
    getSaveUrl?(): string {
        return "FormData/Save";
    }
}


